(function () {
	'use strict';

	var eventNavigationMap = {};
	var lastNavigationMapItem;
	var lastNavigationDirection;
	var multiselectUpdateInProgress;

	angular
		.module('app')
		.factory('calendarIO', [
			'$timeout',
			'$rootScope',
			'$translate',
			'fullCalendarBridge',
			'daybackIO',
			'manageSettings',
			'csvData',
			'seedcodeCalendar',
			'manageSchedules',
			'manageCalendarActions',
			'utilities',
			'environment',
			calendarIO,
		]);

	function calendarIO(
		$timeout,
		$rootScope,
		$translate,
		fullCalendarBridge,
		daybackIO,
		manageSettings,
		csvData,
		seedcodeCalendar,
		manageSchedules,
		manageCalendarActions,
		utilities,
		environment
	) {
		var kioskModeEnabled;
		var revertHistory = {
			undo: undefined,
			events: [],
		};

		var focusedEvent = {};

		return {
			kioskMode: kioskMode,
			showEventPopover: showEventPopover,
			editPopoverCreate: editPopoverCreate,
			refreshEditPopover: refreshEditPopover,
			getFocusEvent: getFocusEvent,
			setFocusEvent: setFocusEvent,
			focusEvent: focusEvent,
			viewDay: viewDay,
			cleanSchedule: cleanSchedule,
			assignEventColor: assignEventColor,
			mutateHook: mutateHook,
			cleanEvent: cleanEvent,
			cleanEvents: cleanEvents,
			assignDefaultResource: assignDefaultResource,
			assignCustomFields: assignCustomFields,
			customFieldOutputTransform: customFieldOutputTransform,
			removeNoFilterValue: removeNoFilterValue,
			replaceWithNoFilterValue: replaceWithNoFilterValue,
			packageTagsOutput: packageTagsOutput,
			addSchedule: addSchedule,
			removeSchedule: removeSchedule,
			refreshScheduleDisplay: refreshScheduleDisplay,
			packageOutput: packageOutput,
			confirmEditCallback: confirmEditCallback,
			getEventFromServer: getEventFromServer,
			getEventChanges: getEventChanges,
			beforeDrop: beforeDrop,
			backupEvent: backupEvent,
			eventChanged: eventChanged,
			updateEvent: updateEvent,
			updateEventTime: updateEventTime,
			clearEventDragStatus: clearEventDragStatus,
			duplicateEvent: duplicateEvent,
			addEvent: addEvent,
			newEvent: newEvent,
			createEvent: createEvent,
			deleteEvent: deleteEvent,
			confirmDelete: confirmDelete,
			deleteEventNow: deleteEventNow,
			cloneMappedField: cloneMappedField,
			createEditEvent: createEditEvent,
			updateEditEvent: updateEditEvent,
			getDisplayEvent: getDisplayEvent,
			eventAction: eventAction,
			saveRequiresConfirmation: saveRequiresConfirmation,
			toggleMultiSelect: toggleMultiSelect,
			fieldExistsForEvent: fieldExistsForEvent,
			runEventActions: runEventActions,
			getMultiselectUpdateInProgress: getMultiselectUpdateInProgress,
			undoHandler: {
				clearRevertFunction: clearRevertFunction,
				saveRevertFunction: saveRevertFunction,
				runRevertFunction: runRevertFunction,
				areEventsShowing: areEventsShowing,
			},
		};

		function kioskMode(refreshMinutes, preventDateChange) {
			var mouseMovedLast = new Date().valueOf();
			var previousDate = moment();
			var refreshTimeout;
			var config = seedcodeCalendar.get('config');

			var mouseMoveRefreshDelay = 1500;
			refreshMinutes = Number(refreshMinutes);

			if (refreshMinutes && refreshMinutes !== NaN && !kioskModeEnabled) {
				refreshMinutes = refreshMinutes >= 1 ? refreshMinutes : 0;
				if (refreshMinutes) {
					kioskModeEnabled = true;
					config.kioskMode = true;
					document.addEventListener('mousemove', mouseMoved);
					document.addEventListener('wheel', mouseMoved);
					window.setTimeout(autoRefresh, 10000);
				}
			}

			function mouseMoved(e) {
				mouseMovedLast = new Date().valueOf();
			}

			function autoRefresh() {
				var now = new Date().valueOf();
				var preventRefresh;
				var refreshDelay;
				var dateCheck;
				var config = seedcodeCalendar.get('config');
				var view = seedcodeCalendar.get('view');

				clearTimeout(refreshTimeout);

				// Don't refresh if a popover is open or an event is being dragged or resized
				if (
					config &&
					config.status &&
					(config.status.preventDrag || config.status.isDragging)
				) {
					refreshDelay = 5000;
					preventRefresh = true;
				}

				// Don't refresh events if the mouse just moved
				else if (mouseMovedLast > now - mouseMoveRefreshDelay) {
					refreshDelay = mouseMoveRefreshDelay;
					preventRefresh = true;
				} else {
					refreshDelay = 1000 * 60 * refreshMinutes;
				}

				if (!preventRefresh && view) {
					dateCheck = moment();

					if (
						!preventDateChange &&
						config.defaultDate &&
						!moment(config.defaultDate).isSame(dateCheck, 'day')
					) {
						previousDate = dateCheck;
						// Navigate to dateCheck
						seedcodeCalendar
							.get('element')
							.fullCalendar('gotoDate', dateCheck);
					}
					// Refresh
					seedcodeCalendar
						.get('element')
						.fullCalendar('refetchEvents');
				}
				refreshTimeout = window.setTimeout(autoRefresh, refreshDelay);
			}
		}

		async function showEventPopover(
			event,
			element,
			view,
			playback,
			fromNavigation
		) {
			// Open popover for unscheduled event
			if (event.unscheduled) {
				return await showPopover();
			}

			// Open popover for calendar event
			const scrollElement = $('.calendar-scroll');
			const scrollElementHeight = scrollElement.height();
			const elementTop = element.position().top;
			const scrollPosition = scrollElement.scrollTop();

			if (
				(elementTop > scrollPosition + scrollElementHeight - 10 ||
					elementTop < scrollPosition + 10) &&
				scrollElement[0].contains(element[0])
			) {
				// scroll it!
				utilities.scrollToY(
					scrollElement[0],
					elementTop - 50,
					1000,
					'easeInOutQuint',
					showPopover
				);
			} else {
				await showPopover();
			}

			function showPopover() {
				return new Promise((resolve, reject) => {
					var revertObject;
					event.eventStatus.showPopover = false;
					editPopoverCreate(
						event,
						element[0],
						true,
						null,
						null,
						playback,
						fromNavigation,
						(editPopover) => {
							if (editPopover) {
								// Return edit popover result / methods
								resolve(editPopover);
							} else {
								reject();
							}
						}
					);
					//Set this so we don't render more than one popover. We clear this in the onEventAfterAllRender function
					if (!view) {
						view = seedcodeCalendar.get('view');
					}

					view.popoverRendered = true;

					//Revert event object data so we can continue to track changes
					if (event.eventStatus.revertObject) {
						revertObject = event.eventStatus.revertObject;
						for (var property in revertObject) {
							event[property] = revertObject[property];
						}
					}
				});
			}
		}

		function getMultiselectUpdateInProgress() {
			return multiselectUpdateInProgress;
		}

		function toggleMultiSelect(
			event,
			shiftKey,
			targetElement,
			view,
			forceDeselect
		) {
			var theId;
			var theElements;
			var id;
			var theClass;
			var re = /multi-select/gi;
			var config = seedcodeCalendar.get('config');
			var multiSelect = seedcodeCalendar.get('multiSelect');
			var selectClass = 'multi-select';
			var notSelectedClass = 'ms-not-selected';
			var transitionClass = 'selected-transition';

			if (
				forceDeselect ||
				event?.unscheduled ||
				targetElement === document.body ||
				targetElement === document
			) {
				deselectAll(multiSelect);
			} else if (!targetElement || !event) {
				//If there is a multiSelect, verify all elements are visible
				if (multiSelect) {
					var visibleSelected = getVisibleSelectedEvents(multiSelect);

					//All multiSelect elements are visible so we can make sure they're still selected
					if (sameSelectedSets(multiSelect, visibleSelected)) {
						updateSelectedClass(visibleSelected);
						seedcodeCalendar.init('multiSelect', visibleSelected);
					}
				}
			} else {
				if (!targetElement.classList) {
					theId =
						targetElement[0].dataset && targetElement[0].dataset.id;
				} else {
					theId = targetElement.dataset && targetElement.dataset.id;
				}
				theElements = document.querySelectorAll(
					'[data-id="' + theId + '"]'
				);

				if (!theId) {
					// if there is no id found it is an error or we are not in a standard calendar view
					// assume we should continue with the regular click and open the popover
					return true;
				}

				if (view.name === 'basicHorizon') {
					targetElement =
						theElements[0].querySelector('.nub-content');
					if (!targetElement) {
						targetElement =
							theElements[0].querySelector('.fc-event-inner');
					}
				}

				id = utilities.stringToID(
					event.eventID + '-' + event.schedule.id
				);
				theClass = targetElement.className;

				if (shiftKey) {
					//shift key down

					$rootScope.$broadcast('closePopovers');
					if (re.test(theClass)) {
						//item is selected / deselect
						//remove class from event element
						toggleElementSelection(theElements, false);
						if (multiSelect && multiSelect[id]) {
							delete multiSelect[id];
						}
						if (
							multiSelect &&
							Object.keys(multiSelect).length === 0
						) {
							//No more selected objects
							multiSelect = null;
							seedcodeCalendar.init('multiSelect', multiSelect);

							//Remove styling on non-selected events
							toggleNotSelectedClass(false, true);
						}
					} else {
						//item is not selected, select now

						//initialize/reset if multiSelect object in seedcode calendar (if empty or no selected items are in view)
						if (
							!multiSelect ||
							document.querySelectorAll(
								'.nub-content.' +
									selectClass +
									',.fc-event.' +
									selectClass +
									''
							).length === 0
						) {
							multiSelect = {};
							seedcodeCalendar.init('multiSelect', multiSelect);
						}

						//class event element
						toggleElementSelection(theElements, true);

						//add element and event object to seedcodeCalendar
						multiSelect[id] = {};
						multiSelect[id].name = view.name;
						multiSelect[id].start = view.start.clone();
						multiSelect[id].columns = config.resources.length;
						multiSelect[id].position = config.resourcePosition;
						multiSelect[id].event = event;
						multiSelect[id].element = theElements;
						multiSelect[id].horizonSlider = config.horizonSlider;

						if (Object.keys(multiSelect).length === 1) {
							//Add styling to non-selected events
							toggleNotSelectedClass(true, true);
						}
					}
					//override native behavior (popover)
					return false;
				} else {
					//shift key not down

					if (multiSelect) {
						var selectedInView = document.querySelectorAll(
							'.' + selectClass
						);

						if (!re.test(theClass)) {
							//if this event isn't selected, then deselect all
							deselectAll(multiSelect);
						} else {
							//ToDo: Until multi-select popover supported, deselect when shift not clicked
							deselectAll(multiSelect);
						}

						return selectedInView.length === 0;
					}
				}
			}

			function updateSelectedClass(selected) {
				var itemsSelected = false;
				for (var thisEvent in selected) {
					if (
						selected[thisEvent].element &&
						selected[thisEvent].element.length > 0
					) {
						itemsSelected = true;
						toggleElementSelection(
							selected[thisEvent].element,
							true
						);
					}
				}

				if (itemsSelected) {
					//Add styling to non-selected events
					toggleNotSelectedClass(true, false);
				}
			}

			function toggleElementSelection(theElements, select) {
				var targetElement;
				var addClass = select ? selectClass : notSelectedClass;
				var removeClass = select ? notSelectedClass : selectClass;

				for (var i = 0; i < theElements.length; i++) {
					if (
						view.name === 'basicHorizon' &&
						theElements[i].className &&
						theElements[i].className.indexOf('nub-container') !== -1
					) {
						targetElement =
							theElements[i].querySelector('.nub-content');
						if (!targetElement) {
							targetElement =
								theElements[i].querySelector('.fc-event-inner');
						}
						targetElement.classList.add(addClass);
						targetElement.classList.remove(removeClass);
					} else {
						theElements[i].classList.add(addClass);
						theElements[i].classList.remove(removeClass);
					}
				}
			}

			function deselectAll(multiSelect) {
				//if we have a multiSelect object, run through the elements and remove the selected class
				//and clear the multi-select object
				if (multiSelect) {
					for (var event in multiSelect) {
						toggleElementSelection(
							multiSelect[event].element,
							false
						);
					}

					//Remove styling on non-selected events
					toggleNotSelectedClass(false, true);

					multiSelect = null;
					seedcodeCalendar.init('multiSelect', multiSelect);
				}
			}

			function toggleNotSelectedClass(addClass, transition) {
				var currentElement;
				var eventElements = document.querySelectorAll(
					'.nub-content,.fc-event'
				);
				var notSelectedElements = document.querySelectorAll(
					'.nub-content:not(.' +
						selectClass +
						'),.fc-event:not(.' +
						selectClass +
						')'
				);

				//Add transition to all event elements
				if (transition) {
					for (
						currentElement = 0;
						currentElement < eventElements.length;
						currentElement++
					) {
						eventElements[currentElement].classList.add(
							transitionClass
						);
						setTimeout(
							function (targetElement) {
								targetElement.classList.remove(transitionClass);
							},
							500,
							eventElements[currentElement]
						);
					}
				}

				//Update class for all not selected event elements
				notSelectedElements.forEach(function (currentElement) {
					if (addClass) {
						currentElement.classList.add(notSelectedClass);
					} else {
						currentElement.classList.remove(notSelectedClass);
					}
				});
			}

			//Allow showing popover if no conditions prevent it.
			return true;
		}

		function checkForInvalidMultiselect(event, revertFunc) {
			var startDiff =
				event.start && event.beforeDrop?.start
					? event.start.clone().diff(event.beforeDrop.start.clone())
					: moment.duration(0);
			var endDiff =
				event.end && event.beforeDrop?.end
					? event.end.clone().diff(event.beforeDrop.end.clone())
					: moment.duration(0);
			var durationChange =
				!event.schedule.unusedMap.end && startDiff !== endDiff;
			var allDayChanged = event.allDay !== event.beforeDrop.allDay;
			var id = utilities.stringToID(
				event.eventID + '-' + event.schedule.id
			);
			var diffDays =
				event.start && event.beforeDrop.start
					? event.start
							.clone()
							.startOf('day')
							.diff(
								event.beforeDrop.start.clone().startOf('day'),
								'days'
							)
					: 0;

			var currentObject;
			var previousEventObject;
			var multiSelect = seedcodeCalendar.get('multiSelect');

			if (
				multiSelect &&
				multiSelect[id] &&
				Object.keys(multiSelect).length > 1
			) {
				//Do not attempt changes if there are any read-only events selected
				for (var key in multiSelect) {
					if (
						multiSelect.hasOwnProperty(key) &&
						multiSelect[key] &&
						multiSelect[key].event
					) {
						currentObject = multiSelect[key];
						if (!currentObject.event.schedule.editable) {
							revertFunc();
							utilities.showModal(
								'Warning',
								'There are read-only events in the selection. Changes cannot be applied to selected events.',
								'ok',
								null,
								null,
								null
							);
							return true;
						}
					}
				}

				//Do not attempt changes to only all day status
				if (allDayChanged) {
					revertFunc();
					utilities.showModal(
						'Warning',
						"This item's all day status was modified and cannot be applied to selected events.",
						'ok',
						null,
						null,
						null
					);
					return true;
				} else if (durationChange) {
					for (var key in multiSelect) {
						if (
							multiSelect.hasOwnProperty(key) &&
							multiSelect[key] &&
							multiSelect[key].event
						) {
							currentObject = multiSelect[key];
							if (
								event.eventID !== currentObject.event.eventID &&
								(previousEventObject
									? previousEventObject
									: event
								).allDay !== currentObject.event.allDay
							) {
								utilities.showModal(
									'Operation Failed',
									'Duration changes cannot be replicated to selections with both all day and timed events.',
									null,
									null,
									'Revert Changes',
									revertFunc
								);
								return true;
							} else if (
								!!(
									previousEventObject
										? previousEventObject
										: event
								).schedule.unusedMap.end !==
								!!currentObject.event.schedule.unusedMap.end
							) {
								utilities.showModal(
									'Operation Failed',
									'Duration changes cannot be replicated to selections with both sources having an end field mapped and sources without an end field mapped.',
									null,
									null,
									'Revert Changes',
									revertFunc
								);
								return true;
							}

							previousEventObject = currentObject.event;
						}
					}
				}
			} else {
				if (multiSelect && !multiSelect[id]) {
					toggleMultiSelect(
						null,
						false,
						null,
						seedcodeCalendar.get('view'),
						true
					);
				}
			}
		}

		function updateMultiSelectEvents(
			event,
			changesObject,
			revertObject,
			revertFunc,
			options
		) {
			var updatingTimeout;
			var curDelta;
			var mixedEvents;
			var mixedEventsMessage;
			var diffDays = 0;
			var errorTimeout = 15000;
			var isUndo = false;
			changesObject = options.originalChangesObject || changesObject;
			var durationChange =
				changesObject.hasOwnProperty('end') &&
				!changesObject.hasOwnProperty('start');
			var beforeDrop = options.beforeDrop || event.beforeDrop;
			revertObject = options.beforeDrop || revertObject;

			var revertMessage =
				'<span class="message-icon-separator success">' +
				'<i class="fa fa-lg fa-check"></i>' +
				'</span>' +
				'<span translate>Selected Events Updated</span>' +
				'<span class="message-separator"> | </span>' +
				'<span translate>Undo</span>' +
				'<span class="message-icon-separator" style="opacity: 0.8;"><i class="fa fa-lg fa-undo"></i></span>';

			var id = utilities.stringToID(
				event.eventID + '-' + event.schedule.id
			);
			var selectedEvents = seedcodeCalendar.get('multiSelect');
			var sortedEvents = [];
			var selectedEventCount = selectedEvents
				? Object.keys(selectedEvents).length
				: 1;
			var updatedEventCount = 0;
			var allDayChanged =
				changesObject.hasOwnProperty('allDay') &&
				changesObject.allDay !== revertObject.allDay;
			var config = seedcodeCalendar.get('config');
			var helpers = seedcodeCalendar.get('actionHelpers');
			var updatingMessage =
				'Error occurred during save. Reverting changes.';

			//Only continue if there are selected events and the user modified event was dragged
			if (
				selectedEventCount > 1 &&
				sameSelectedSets(
					selectedEvents,
					getVisibleSelectedEvents(selectedEvents)
				) &&
				selectedEvents[id] &&
				beforeDrop
			) {
				//Calculate the delta to apply to events
				if (durationChange) {
					diffDays = changesObject.end
						.clone()
						.startOf('day')
						.diff(revertObject.end.clone().startOf('day'), 'days');
					curDelta = event.allDay
						? changesObject.end
								.clone()
								.startOf('day')
								.diff(revertObject.end.clone().startOf('day'))
						: changesObject.end
								.clone()
								.diff(revertObject.end.clone());
				} else if (changesObject.start) {
					diffDays = changesObject.start
						.clone()
						.startOf('day')
						.diff(
							revertObject.start.clone().startOf('day'),
							'days'
						);
					curDelta =
						allDayChanged || event.allDay
							? changesObject.start
									.clone()
									.startOf('day')
									.diff(
										revertObject.start
											.clone()
											.startOf('day')
									)
							: changesObject.start
									.clone()
									.diff(revertObject.start.clone());
				}

				//Nothing to do if there is no date/time/resource/status change, this is a new event, event changed schedules, or this is an undo save
				if (
					(!curDelta &&
						!allDayChanged &&
						!changesObject.hasOwnProperty('resource') &&
						!changesObject.hasOwnProperty('status') &&
						!event.eventID) ||
					changesObject.hasOwnProperty('eventSource') ||
					options.isUndo
				) {
					return;
				} else {
					selectedEvents = translateMultiSelectObject(
						selectedEvents,
						curDelta,
						diffDays,
						durationChange,
						config.slotDuration
					);

					for (var multiselectId in selectedEvents) {
						sortedEvents.push([
							multiselectId,
							selectedEvents[multiselectId].event.start,
						]);
					}
					sortedEvents.sort(function (a, b) {
						return a[1].diff(b[1]);
					});

					//Do not attempt changes to only all day status
					if (diffDays === 0 && allDayChanged) {
						utilities.showModal(
							'Warning',
							"This item's all day status was modified and cannot be applied to selected events.",
							'ok',
							cancelEventSave,
							null,
							null
						);
					}

					//Do not attempt duration changes with mixed events
					else if (durationChange && mixedEvents) {
						utilities.showModal(
							'Warning',
							'Duration changes cannot be replicated to selections with ' +
								mixedEventsMessage,
							'ok',
							cancelEventSave,
							null,
							null
						);
					}

					//Go straight to processing the events if based on selected events
					else {
						showUpdatingMessage('Updating Selected Events...');
						queueUpdateSelectedEvents(selectedEvents);
					}
				}
			} else {
				//Clear the multiSelect object as an event was modified and multiselect
				if (selectedEvents) {
					toggleMultiSelect(
						null,
						false,
						null,
						seedcodeCalendar.get('view'),
						true
					);
				}
				return;
			}

			function cancelEventSave() {
				return false;
			}

			function setUpdatingTimeout() {
				clearTimeout(updatingTimeout);
				updatingTimeout = setTimeout(function () {
					helpers.showMessage(updatingMessage, 0, 3000, 'error');
					if (!isUndo && updatedEventCount > 0) {
						clearRevertFunction();
						revertChanges(true);
					} else {
						clearUpdatingMessage();
					}
				}, errorTimeout);
			}

			function recordFailedEdit(callback, targetEvent, error) {
				verifyFinished(targetEvent, null, null, null, null, error);
			}

			function verifyFinished(
				updatedEvent,
				changesObject,
				revertObject,
				revertFunc,
				options,
				error
			) {
				var failedEvents;
				var matchingEvent =
					selectedEvents[
						utilities.stringToID(
							updatedEvent.eventID +
								'-' +
								updatedEvent.schedule.id
						)
					];
				if (updatedEvent && matchingEvent) {
					updatedEventCount += 1;

					if (error) {
						matchingEvent.error =
							error.error && error.error.message
								? error.error.message
								: error.message
									? error.message
									: error.ERRORCODE
										? error.ERRORCODE +
											' - ' +
											error.DESCRIPTION
										: error.errorCode
											? error.errorCode
											: 'Unknown';
					} else {
						matchingEvent.updated = true;
						matchingEvent.event = updatedEvent;
						matchingEvent.revertObject = revertObject;
						matchingEvent.revertFunc = revertFunc;
					}

					if (!error && updatedEventCount < selectedEventCount) {
						updateSelectedEvent(
							selectedEvents[sortedEvents[updatedEventCount][0]]
						);
					} else {
						clearUpdatingMessage();

						if (isUndo) {
							//Get updated eventIDs and check if all showing
							var eventIDs =
								Object.keys(selectedEvents).map(
									function (event) {
										return event.split('-')[0];
									}
								) || [];

							let successMessage =
								'<span translate>Changes Reverted</span>';
							let eventsShowing = areEventsShowing(eventIDs);

							if (!eventsShowing) {
								successMessage =
									'<span translate>Changes Reverted</span>. <span translate>Event may be off screen</span>.';
							}

							let revertConfirmedMessage =
								'<span class="message-icon-separator success">' +
								'<i class="fa fa-lg fa-check"></i>' +
								'</span>' +
								successMessage;

							helpers.showMessage(
								revertConfirmedMessage,
								0,
								3000
							);
						} else {
							failedEvents = Object.keys(selectedEvents)
								.filter(function (key) {
									return selectedEvents[key].error;
								})
								.map(function (key) {
									return selectedEvents[key];
								});
							if (failedEvents.length > 0) {
								//One or more updates failed, revert all changes
								utilities.showModal(
									'Error during save',
									failedEvents[0].error +
										'. Changes will be reverted.',
									'continue',
									revertChanges
								);
							} else {
								//show a custom undo modal
								var config = seedcodeCalendar.get('config');
								config.suppressMultiUpdateUndoMessage = true;

								//Get updated eventIDs from selectedEvents keys for revertFunction
								var eventIDs =
									Object.keys(selectedEvents).map(
										function (event) {
											return event.split('-')[0];
										}
									) || [];

								saveRevertFunction(
									revertChanges,
									null,
									eventIDs
								);
								helpers.showMessage(
									revertMessage,
									0,
									config.undoTimeout,
									null,
									runRevertFunction
								);
							}
						}
					}
				} else {
					clearUpdatingMessage();
					if (isUndo) {
						helpers.showMessage(
							'Error during save - Unexpected result from editEvent function',
							0,
							5000,
							'error'
						);
						clearRevertFunction();
					} else {
						utilities.showModal(
							'Error during save',
							'Unexpected result from editEvent function. Changes will be reverted.',
							'continue',
							revertChanges
						);
						clearRevertFunction();
					}
				}
			}

			function revertChanges(showError) {
				isUndo = true;

				showUpdatingMessage('Reverting Changes...');
				if (showError) {
					updatingMessage = 'Error during undo - Timeout';
					setUpdatingTimeout();
				}

				//First get count of only events that were updated before reverting changes
				selectedEventCount = Object.keys(selectedEvents)
					.filter(function (key) {
						return selectedEvents[key].updated;
					})
					.map(function (key) {
						return selectedEvents[key];
					}).length;

				updatedEventCount = 0;

				if (selectedEventCount > 0) {
					queueUpdateSelectedEvents(selectedEvents);
				} else {
					clearUpdatingMessage();
				}
			}

			function queueUpdateSelectedEvents(selectedEvents) {
				// Perform update requests SYNCHRONOUSLY to avoid issues with callbacks in On or Before Event Save actions
				updateSelectedEvent(selectedEvents[sortedEvents[0][0]]);
			}

			function updateSelectedEvent(selectedEvent) {
				// Only apply changes to user modified event if undo (save already done)
				if (
					(isUndo && selectedEvent.updated) ||
					event.eventID !== selectedEvent.event.eventID ||
					event.schedule !== selectedEvent.event.schedule
				) {
					var actionResult;

					if (!isUndo) {
						// Run before event save actions if any exist
						var actionCallbacks = {
							confirm: function (action) {
								setUpdatingTimeout();
								getEventChanges(
									selectedEvent.event,
									null,
									recordFailedEdit,
									verifyFinished,
									selectedEvent.options,
									isUndo
										? selectedEvent.revertChangesArray
										: selectedEvent.eventChangesArray
								);
							},
							cancel: function (action) {
								verifyFinished(selectedEvent.event);
							},
						};

						actionResult = runEventActions(
							'beforeEventSave',
							selectedEvent.event,
							true,
							actionCallbacks,
							null
						);
					}

					// Only continue now if we have an actionResult or we're undoing multiselect event changes
					if (isUndo || actionResult) {
						setUpdatingTimeout();
						getEventChanges(
							selectedEvent.event,
							null,
							recordFailedEdit,
							verifyFinished,
							selectedEvent.options,
							isUndo
								? selectedEvent.revertChangesArray
								: selectedEvent.eventChangesArray
						);
					} else {
						// We need to cancel the error timeout in case there is user interaction required
						clearTimeout(updatingTimeout);
					}
				} else {
					event.updated = verifyFinished(event);
				}
			}

			function translateMultiSelectObject(
				multiSelect,
				curDelta,
				diffDays,
				durationChange,
				minDuration
			) {
				var userModified;
				var currentObject;
				var eventObject;
				var previousEventObject;
				var id;
				var newEnd;
				var result = {};
				var resourceDiff = utilities.arrayDiff(
					revertObject.resource,
					changesObject.resource
				);
				var statusDiff = utilities.arrayDiff(
					revertObject.status,
					changesObject.status
				);

				minDuration = minDuration.split(':');

				for (var key in multiSelect) {
					if (
						multiSelect.hasOwnProperty(key) &&
						multiSelect[key] &&
						multiSelect[key].event
					) {
						currentObject = multiSelect[key];
						userModified =
							event.eventID === currentObject.event.eventID &&
							event.schedule.id ===
								currentObject.event.schedule.id;
						eventObject = currentObject.event;
						id = utilities.stringToID(
							currentObject.event.eventID +
								'-' +
								currentObject.event.schedule.id
						);

						currentObject.options = {
							endShifted: userModified && !beforeDrop,
						};

						if (userModified) {
							currentObject.eventChangesArray = changesObject;
							currentObject.revertChangesArray =
								createRevertChangesArray(
									revertObject,
									changesObject
								);
						} else {
							currentObject.eventChangesArray = {};
							currentObject.revertChangesArray = {};
							if (changesObject.start) {
								currentObject.eventChangesArray.start =
									durationChange
										? eventObject.start.clone()
										: eventObject.allDay
											? eventObject.start
													.clone()
													.add(diffDays, 'days')
											: eventObject.start
													.clone()
													.add(curDelta);
								currentObject.revertChangesArray.start =
									eventObject.start.clone();
							}
							if (changesObject.end) {
								newEnd = eventObject.allDay
									? eventObject.end
											.clone()
											.add(diffDays, 'days')
									: eventObject.end.clone().add(curDelta);
								if (durationChange) {
									newEnd = moment.max(
										newEnd,
										eventObject.allDay
											? eventObject.start
													.clone()
													.add(1, 'days')
											: eventObject.start.clone().add({
													hours: minDuration[0],
													minutes: minDuration[1],
													seconds: minDuration[2],
												})
									);
								}
								currentObject.eventChangesArray.end = newEnd;
								currentObject.revertChangesArray.end =
									eventObject.end.clone();
							}
							if (
								!durationChange &&
								eventObject.schedule.unusedMap.end
							) {
								delete currentObject.eventChangesArray.end;
								delete currentObject.revertChangesArray.end;
							}
							if (changesObject.hasOwnProperty('resource')) {
								currentObject.eventChangesArray.resource =
									calculateUpdatedArray(
										eventObject.resource.slice(0),
										resourceDiff[0],
										resourceDiff[1]
									);
								currentObject.revertChangesArray.resource =
									eventObject.resource.slice(0);
							}
							if (changesObject.hasOwnProperty('status')) {
								currentObject.eventChangesArray.status =
									calculateUpdatedArray(
										eventObject.status.slice(0),
										statusDiff[0],
										statusDiff[1]
									);
								currentObject.revertChangesArray.status =
									eventObject.status.slice(0);
							}
						}
						result[id] = currentObject;

						if (!mixedEvents) {
							if (
								(previousEventObject
									? previousEventObject
									: event
								).allDay !== eventObject.allDay
							) {
								mixedEvents = true;
								mixedEventsMessage =
									'both all day and timed events.';
							} else if (
								!!(
									previousEventObject
										? previousEventObject
										: event
								).schedule.unusedMap.end !==
								!!eventObject.schedule.unusedMap.end
							) {
								mixedEvents = true;
								mixedEventsMessage =
									'both sources having an end field mapped and sources without an end field mapped.';
							}
						}
						previousEventObject = eventObject;
					}
				}
				return result;

				function calculateUpdatedArray(
					valueToUpdate,
					fromValue,
					toValue
				) {
					if (valueToUpdate.indexOf(toValue) > -1) {
						if (valueToUpdate.indexOf(fromValue) > -1) {
							valueToUpdate.splice(
								valueToUpdate.indexOf(fromValue),
								1
							);
						}
					} else {
						if (valueToUpdate.indexOf(fromValue) > -1) {
							valueToUpdate.splice(
								valueToUpdate.indexOf(fromValue),
								1,
								toValue
							);
						} else {
							valueToUpdate = [toValue];
						}
					}
					return valueToUpdate;
				}
			}

			// Function used when updating multiselect events
			function showUpdatingMessage(message) {
				multiselectUpdateInProgress = true;

				// Show the message
				utilities.showMessage(message, 0, 2 * 60 * 1000);

				// Disable dragging
				config.status.preventDrag = true;
				config.status.preventResize = true;
			}

			// Function for clearing updating/reverting message and timeout
			function clearUpdatingMessage() {
				multiselectUpdateInProgress = false;

				// Clear the timeout
				clearTimeout(updatingTimeout);
				utilities.hideMessages('message');

				// Re-enable dragging
				config.status.preventDrag = false;
				config.status.preventResize = false;
			}

			function createRevertChangesArray(revertObject, changesObject) {
				var result = {};
				for (var prop in changesObject) {
					if (moment.isMoment(revertObject[prop])) {
						result[prop] = revertObject[prop].clone();
					} else if (Array.isArray(revertObject[prop])) {
						result[prop] = revertObject[prop].slice(0);
					} else {
						result[prop] = revertObject[prop];
					}
				}
				return result;
			}
		}

		function getVisibleSelectedEvents(selected) {
			var targetElement;
			//refresh or filter selection, restore selection
			var elements = [];
			var multiSelect = {};
			var view = seedcodeCalendar.get('view');
			var config = seedcodeCalendar.get('config');
			//gather the event elements so we can update their class.
			if (selected) {
				var containers;
				if (view.name === 'basicHorizon') {
					containers =
						document.getElementsByClassName('fc-nub-container');
					elements = extractContainerElements(containers);
				}
				containers =
					document.getElementsByClassName('fc-event-container');
				elements = elements.concat(
					extractContainerElements(containers)
				);

				//just save the event ids and clear.
				var eventIds = Object.keys(selected);
				//get our client events and gather the selected
				var clientEvents = seedcodeCalendar
					.get('element')
					.fullCalendar('clientEvents');

				for (var e = 0; e < elements.length; e++) {
					var elementId =
						elements[e].dataset && elements[e].dataset.id
							? elements[e].dataset && elements[e].dataset.id
							: false;
					if (elementId) {
						for (var i = 0; i < clientEvents.length; i++) {
							var scheduleId = config.isShare
								? clientEvents[i].shareScheduleID
								: clientEvents[i].schedule.id;
							var thisId = utilities.stringToID(
								clientEvents[i].eventID + '-' + scheduleId
							);
							var thisElementId = clientEvents[i]._id;
							if (thisElementId === elementId) {
								//client event matches this visible element
								//now loop through our previously selected set to see if the event is there.
								for (var c = 0; c < eventIds.length; c++) {
									if (eventIds[c] == thisId) {
										//visible event is selected, add to new set
										if (!multiSelect[thisId]) {
											multiSelect[thisId] = {};
											multiSelect[thisId].element = [];
										}
										multiSelect[thisId].event =
											clientEvents[i];
										multiSelect[thisId].name = view.name;
										multiSelect[thisId].start =
											view.start.clone();
										multiSelect[thisId].columns =
											config.resources.length;
										multiSelect[thisId].position =
											config.resourcePosition;
										if (view.name === 'basicHorizon') {
											targetElement =
												elements[e].querySelector(
													'.nub-content'
												);
											if (!targetElement) {
												targetElement =
													elements[e].querySelector(
														'.fc-event-inner'
													);
											}

											if (targetElement) {
												multiSelect[
													thisId
												].element.push(targetElement);
											}
										}
										multiSelect[thisId].element.push(
											elements[e]
										);
									}
								}
							}
						}
					}
				}
			}
			if (Object.keys(multiSelect).length > 0) {
				return multiSelect;
			} else {
				return null;
			}
		}

		function sameSelectedSets(oldSelected, newSelected) {
			var matchCount = 0;
			for (var oldEvent in oldSelected) {
				for (var newEvent in newSelected) {
					if (newEvent === oldEvent) {
						matchCount++;
					}
				}
			}
			if (
				Object.keys(oldSelected).length === matchCount &&
				Object.keys(newSelected).length === matchCount
			) {
				return true;
			} else {
				return false;
			}
		}

		function extractContainerElements(containers) {
			var result = [];
			for (var n = 0; n < containers.length; n++) {
				if (containers[n].children) {
					for (var h = 0; h < containers[n].children.length; h++) {
						result.push(containers[n].children[h]);
					}
				}
			}
			return result;
		}

		function editPopoverCreate(
			event,
			element,
			forceAllow,
			jsEvent,
			firstOpenOverride,
			fromPlayback,
			fromNavigation,
			callback,
			typeOverride
		) {
			var container;
			var externalEdits;
			var fieldMap;
			var customFields;
			var eventActions;
			var preventDefaultAction;
			var preventDefaultActionOverride;
			var focusEventElement;
			var customActions;
			var labelMap;
			var unusedMap;
			var allowHTMLMap;
			var hiddenFieldMap;
			var readOnlyFieldMap;
			var sharedSourceTypeID;
			var sharedSource;
			var config = seedcodeCalendar.get('config');
			var view = seedcodeCalendar.get('view');
			var isPhone = environment.isPhone;
			var firstOpen = firstOpenOverride
				? true
				: event && event.eventStatus
					? event.eventStatus.firstOpen
					: false;
			var popoverTarget =
				event &&
				event.eventStatus &&
				event.eventStatus.popoverTargetElement &&
				firstOpen
					? event.eventStatus.popoverTargetElement
					: element;
			var popoverTemplate = '<edit-event></edit-event>';
			var platform = utilities.getDBKPlatform();
			var isUnscheduled = event.unscheduled;
			var isMap = typeOverride === 'map' ? true : false;
			var focusType = isMap ? 'event' : 'map';

			var targetOffset = isMap ? {x: 0, y: -6} : {x: 0, y: 0};

			var onCloseResolve;
			var onCloseReject;

			var onClosePromise = () => {
				return new Promise((resolve, reject) => {
					onCloseResolve = resolve;
					onCloseReject = reject;
				});
			};

			let drawerWidth = 0;

			// If the schedule has a drawer open by default, account for that in the offset
			if (
				!isPhone &&
				event.schedule.defaultDrawer &&
				event.schedule.defaultDrawer !== ''
			) {
				const tempElement = document.createElement('div');
				tempElement.classList = 'utility-drawer';
				tempElement.style.display = 'none';
				document.body.appendChild(tempElement);
				drawerWidth = parseInt(getComputedStyle(tempElement).width);
				document.body.removeChild(tempElement);
			}

			//Clear Multiselect
			toggleMultiSelect(
				null,
				false,
				null,
				seedcodeCalendar.get('view'),
				true
			);

			if (firstOpenOverride) {
				if (!event.eventStatus) {
					event.eventStatus = {};
				}
				event.eventStatus.firstOpen = firstOpen;
			}

			// var popoverTemplate = firstOpen && !isPhone ? "<div ng-include=\"'app/event/event-quick.html'\"></div>" : '<edit-event></edit-event>';
			if (config.preventPopover && !forceAllow) {
				config.preventPopover = false;
				if (callback) {
					callback();
				}
				return;
			}

			if (isUnscheduled || isMap) {
				container = 'body';
			} else if (isPhone) {
				container = '#calendar-container';
			} else if (
				angular
					.element(popoverTarget)[0]
					.classList.contains('more-events')
			) {
				container = 'body'; // Opening popover on more events text rather than event so append to body
			} else if (
				angular.element(popoverTarget).parents('.fc-slot-scroll').length
			) {
				container = '.fc-slot-scroll';
			} else {
				container = '.fc-content';
			}

			//If a focus action exists run it now
			if (event.schedule.source && event.schedule.source.eventFocus) {
				event.schedule.source.eventFocus(event.schedule, event);
			}

			externalEdits = event.schedule.externalEdits;

			//Load share specific settings for event popover
			if (config.isShare) {
				var shareSources = seedcodeCalendar.get('shareSources');
				for (var property in shareSources) {
					if (
						(event.shareSourceID === shareSources[property].id &&
							!shareSources[property].localParent) ||
						event.shareScheduleID === shareSources[property].id
					) {
						sharedSourceTypeID =
							shareSources[property].sourceTypeID;
						sharedSource = shareSources[property];
						customActions = getValidShareActions(
							'customActions',
							shareSources[property],
							event
						);
						eventActions = getValidShareActions(
							'eventActions',
							shareSources[property],
							event
						);
						customFields = utilities.getValidShareCustomFields(
							shareSources[property],
							event
						);
						labelMap = getValidShareDataMap(
							'labelMap',
							shareSources[property],
							event
						);
						unusedMap = getValidShareDataMap(
							'unusedMap',
							shareSources[property],
							event
						);
						allowHTMLMap = getValidShareDataMap(
							'allowHTMLMap',
							shareSources[property],
							event
						);
						hiddenFieldMap = getValidShareDataMap(
							'hiddenFieldMap',
							shareSources[property],
							event
						);
						readOnlyFieldMap = getValidShareDataMap(
							'readOnlyFieldMap',
							shareSources[property],
							event
						);

						//add default unused map items from the sharesource template
						if (
							shareSources[property].sourceTemplate &&
							shareSources[property].sourceTemplate.unusedMap
						) {
							if (!unusedMap) {
								unusedMap = {};
							}
							for (var mapItem in shareSources[property]
								.sourceTemplate.unusedMap) {
								unusedMap[mapItem] =
									shareSources[
										property
									].sourceTemplate.unusedMap[mapItem];
							}
						}

						fieldMap = JSON.parse(
							JSON.stringify(
								seedcodeCalendar.get('eventDictionary')
							)
						); // Clone the fieldmap so we don't mutate the original

						//Add custom fields to field map
						if (customFields) {
							for (var field in customFields) {
								fieldMap[field] = customFields[field].field;
							}
						}

						break;
					}
				}
			} else {
				customFields = event.schedule.customFields;
				customActions = getValidActions(
					event.schedule.customActions,
					event
				);
				eventActions = getValidActions(
					event.schedule.eventActions,
					event
				);
				labelMap = event.schedule.labelMap;
				unusedMap = event.schedule.unusedMap;
				allowHTMLMap = event.schedule.allowHTMLMap;
				hiddenFieldMap = event.schedule.hiddenFieldMap;
				readOnlyFieldMap = event.schedule.readOnlyFieldMap;
			}

			var values = createEditEvent(event, fieldMap);
			values.sharedSourceTypeID = sharedSourceTypeID;
			values.sharedSource = sharedSource;

			//Set default drawer to open
			values.defaultDrawer = event.schedule.defaultDrawer;

			//Clone unused map to unused object
			values.unused = cloneMappedField(
				unusedMap,
				values.schedule.sourceTypeID,
				values.sharedSourceTypeID
			);
			values.allowHTML = cloneMappedField(
				allowHTMLMap,
				values.schedule.sourceTypeID,
				values.sharedSourceTypeID
			);

			values.hiddenField = cloneMappedField(
				hiddenFieldMap,
				values.schedule.sourceTypeID,
				values.sharedSourceTypeID
			);

			values.readOnlyField = cloneMappedField(
				readOnlyFieldMap,
				values.schedule.sourceTypeID,
				values.sharedSourceTypeID
			);

			values.customFields = customFields;
			values.customActions = customActions;
			values.labelMap = labelMap;

			//Check if event is part of playback group
			if (
				event.metadata &&
				event.metadata.linkedID &&
				event.metadata.linkedPlayback
			) {
				var linkedEvents = {};
				var linkedSegmentList = [];
				var calendarElement = seedcodeCalendar.get('element');
				var shownSegments =
					(view.clientSlotSegments
						? view.clientSlotSegments()
						: view.clientSegments()) || []; //calendarElement.fullCalendar('clientEvents', null, true);
				var instance = angular
					.element(popoverTarget)[0]
					.getAttribute('data-instance');

				if (
					!fromNavigation ||
					(fromNavigation &&
						lastNavigationDirection &&
						fromNavigation !== lastNavigationDirection)
				) {
					eventNavigationMap = {};

					if (fromNavigation && lastNavigationDirection) {
						eventNavigationMap[lastNavigationMapItem] = true;
					}
				}

				lastNavigationDirection = fromNavigation;

				var linkedPosition = 0;
				for (var i = 0; i < shownSegments.length; i++) {
					if (
						shownSegments[i].event.metadata &&
						shownSegments[i].event.metadata.linkedID ===
							event.metadata.linkedID
					) {
						linkedSegmentList.push(shownSegments[i]);
						if (
							shownSegments[i].event._id === event._id &&
							Number(instance) === shownSegments[i].instance
						) {
							linkedEvents.position = linkedPosition;
							linkedEvents.instance = Number(instance);
							linkedEvents.eventNavigationMap =
								eventNavigationMap;
						}

						linkedPosition++;
					}
				}

				if (
					linkedEvents.position === 0 ||
					checkFirstSegment(linkedSegmentList, linkedEvents.position)
				) {
					linkedEvents.first = true;
				}
				if (
					linkedEvents.position === linkedSegmentList.length - 1 ||
					checkLastSegment(linkedSegmentList, linkedEvents.position)
				) {
					linkedEvents.last = true;
				}
				if (
					linkedEvents.position > 0 &&
					linkedEvents.position < linkedSegmentList.length - 1
				) {
					var firstTarget =
						linkedSegmentList[linkedEvents.position - 1];
					var firstTargetElement = firstTarget
						? calendarElement.find(
								".fc-event-container [data-id='" +
									firstTarget.event._id +
									"']"
							)
						: null;
					var lastTarget =
						linkedSegmentList[linkedEvents.position + 1];
					var lastTargetElement = lastTarget
						? calendarElement.find(
								".fc-event-container [data-id='" +
									lastTarget.event._id +
									"']"
							)
						: null;

					if (!firstTargetElement || !firstTargetElement.length) {
						linkedEvents.first = true;
					}

					if (!lastTargetElement || !lastTargetElement.length) {
						linkedEvents.last = true;
					}
				}

				eventNavigationMap[event._id] = true;
				lastNavigationMapItem = event._id;

				linkedEvents.segments = linkedSegmentList;
				linkedEvents.playback = fromPlayback;
				linkedEvents.navigation = fromNavigation;
				values.linkedEvents = linkedEvents;
			}

			const actionCallbacks = {
				confirm: function (action) {
					action.preventAction = true;
					editPopoverCreate(
						event,
						element,
						forceAllow,
						null,
						firstOpen,
						null,
						null,
						callback
					);
				},
				cancel: function (action) {
					//Logic for a cancel function could go here
					if (callback) {
						callback();
					}
				},
			};

			const clickType = event.unscheduled
				? 'unscheduled'
				: isMap
					? 'map'
					: 'event';

			const params = {
				data: {type: clickType},
			};
			const actionResult = runEventActions(
				'eventClick',
				values,
				true,
				actionCallbacks,
				null,
				null,
				null,
				null,
				popoverTarget,
				jsEvent,
				params
			);

			if (!actionResult) {
				return false;
			}

			// // Todo: replace the stuff blow with the function call above
			// //Get an event action if any exist
			// if (eventActions) {
			// 	for (var property in eventActions) {
			// 		if (
			// 			eventActions[property].type === 'eventClick' &&
			// 			eventActions[property].url
			// 		) {
			// 			if (eventActions[property].preventAction) {
			// 				eventActions[property].preventAction = null;
			// 			} else {
			// 				eventActions[property].callbacks = {
			// 					confirm: function () {
			// 						eventActions[property].preventAction = true;
			// 						editPopoverCreate(
			// 							event,
			// 							element,
			// 							forceAllow,
			// 							null,
			// 							firstOpen,
			// 							null,
			// 							null,
			// 							callback
			// 						);
			// 					},
			// 					cancel: function () {
			// 						//Logic for a cancel function could go here
			// 						if (callback) {
			// 							callback();
			// 						}
			// 					},
			// 				};
			//
			// 				var clickType = event.unscheduled
			// 					? 'unscheduled'
			// 					: isMap
			// 					? 'map'
			// 					: 'event';
			//
			// 				var params = {
			// 					data: {type: clickType},
			// 				};
			//
			// 				var actionResult = eventAction(
			// 					values,
			// 					eventActions[property],
			// 					null,
			// 					true,
			// 					null,
			// 					null,
			// 					popoverTarget,
			// 					jsEvent,
			// 					null,
			// 					params
			// 				);
			//
			// 				if (actionResult === true) {
			// 					//If the result of a event action function is true we override prevent default
			// 					preventDefaultActionOverride = true;
			// 				}
			// 				if (
			// 					eventActions[property].preventDefault &&
			// 					!preventDefaultActionOverride
			// 				) {
			// 					preventDefaultAction = true;
			// 				}
			// 			}
			// 		}
			// 	}
			//
			// 	if (preventDefaultAction && !preventDefaultActionOverride) {
			// 		// No callback here because this means we are relying on action callbacks
			// 		return false;
			// 	}
			// }

			if (externalEdits && platform !== 'dbkfm') {
				if (
					event.schedule.editLayoutName &&
					event.schedule.source.showEventOnLayout
				) {
					event.schedule.source.showEventOnLayout(
						event.schedule.editLayoutName,
						event
					);
					if (callback) {
						callback();
					}
					return;
				}
			} else if (externalEdits) {
				//Could be event.source.useFilemaker or something like that
				var queryID = new Date().getTime(); //Used to create a unique id for our query
				var start = moment(event.start);
				var end = event.allDay
					? moment(event.end).subtract('day', 1)
					: moment(event.end); //adjust end date to be inclusive if it is all day
				if (!event.eventID) {
					var eventChanges = backupEvent(event);
					event.schedule.source.editEvent(
						event,
						null,
						null,
						eventChanges,
						null,
						showExternalEvent
					);
				} else {
					showExternalEvent(event);
				}
				if (callback) {
					callback();
				}
				return;
			}

			function showExternalEvent(event) {
				var config = seedcodeCalendar.get('config');
				var queryID = new Date().getTime(); //Used to create a unique id for our query
				var start = moment(event.start);
				var end = moment(event.end);
				utilities.scriptURL(
					'script=' +
						encodeURIComponent(
							'Show Event Details From WebViewer'
						) +
						'&$eventID=' +
						event.eventID +
						'&$source=' +
						event.eventSource +
						'&$start=' +
						start.format(
							config.databaseDateFormat + ' ' + 'HH:mm:ss'
						) +
						'&$end=' +
						end.format(
							config.databaseDateFormat + ' ' + 'HH:mm:ss'
						) +
						'&$allDay=' +
						event.allDay +
						'&$queryID=' +
						queryID
				);
			}

			const classNames = event.className
				? Array.isArray(event.className)
					? event.className
					: [event.className]
				: [];

			// Reset any existing focus event
			clearFocusEvent();

			if (isMap) {
				classNames.push('map');
				focusEventElement = focusEvent(
					event,
					focusType,
					`.fc-event[data-id="${event._id}"]`
				);
				// Focus event
			} else {
				// Focus map marker
				//

				focusEventElement = focusEvent(
					event,
					focusType,
					`.dbk-map-marker[data-id="${event._id}"]`,
					true
				);
			}

			// Get the container rectangle for use in positioning the popover
			const containerRect = document
				.querySelector(container)
				?.getBoundingClientRect();

			var popover = {
				controller: 'EventCtrl',
				target: popoverTarget,
				useTargetOffset: container === 'body' ? true : false,
				offsetX: targetOffset.x,
				offsetY: targetOffset.y,
				container: container,
				clickX: jsEvent?.clientX
					? jsEvent.clientX - containerRect.left
					: null,
				clickY: jsEvent?.clientY
					? jsEvent.clientY - containerRect.top
					: null,
				drawerWidth: drawerWidth,
				type: isPhone ? 'mobile' : 'popover', // modal or popover
				class: classNames.length ? classNames.join(' ') : '',
				firstOpen: firstOpen,
				quickSave: firstOpen && !isPhone,
				width: 'auto',
				reserveWidth: isPhone ? null : 502,
				staticHeight: true,
				// positionX: jsEvent.pageX,
				// positionY: jsEvent.pageY,
				direction: 'auto',
				data: values,
				dataTitle: 'edit',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: function (editEvent, configuration, jsEvent) {
					var changed;

					//Run event actions if any exist (only if we aren't deleting the event)
					if (!editEvent.event.removed) {
						var actionCallbacks = {
							confirm: function (action) {
								action.preventAction = true;
								//Wrap in timeout so we force a digest cycle and so the watcher fires properly to close the popover
								$timeout(function () {
									popover.show = false; //Hide the popover
								}, 0);
							},
							cancel: function (action) {
								//Some functionality can go here that represents a cancel callback
							},
						};
						var actionResult = runEventActions(
							'beforeEventSave',
							editEvent,
							true,
							actionCallbacks,
							null
						);
						//Return if the action result doesn't return true as we are preventing the default action
						if (!actionResult) {
							return false;
						}
					}

					//Re-enable dragging
					config.status.preventDrag = false;
					config.status.preventResize = false;

					if (
						saveRequiresConfirmation(editEvent.event) &&
						!this.confirmed
					) {
						changed = eventChanged(editEvent, editEvent.event);
						if (changed) {
							if (jsEvent) {
								jsEvent.stopPropagation();
								jsEvent.stopImmediatePropagation();
								jsEvent.preventDefault();
							}
							confirmSaveModal(
								editEvent.event,
								editEvent,
								null,
								modalRevertEvent,
								confirmCallback,
								changed
							);
							return false;
						}
					}
					function confirmCallback() {
						popover.confirmed = true; //Set this so we know that this came form a confirmation dialog and we don't need to save here
						popover.show = false; //Hide the popover
					}
					function modalRevertEvent() {
						updateEditEvent(editEvent.event, editEvent);
					}
				},
				onHidden: function (editEvent, jsEvent) {
					//Open full popover
					if (popover.firstOpen && !popover.quickSave && !isPhone) {
						var changes = eventChanged(editEvent, editEvent.event);

						applyEventChanges(editEvent.event, editEvent, changes);
						if (changes.eventSource) {
							//If the calendar has changed update values appropriately
							editEvent.event.schedule = editEvent.schedule;
							if (editEvent.event.isDefaultResource) {
								editEvent.event.isDefaultResource = false;
								editEvent.event.resource = [];
							}
							if (
								!isFilterAssigned(editEvent.event.resource) &&
								editEvent.schedule.defaultResource
							) {
								editEvent.event.isDefaultResource = true;
								editEvent.event.resource = [
									editEvent.schedule.defaultResource.name,
								];
							}
						}
						cleanEvent(editEvent.event);
						editPopoverCreate(editEvent.event, element);
					} else {
						//Save event from quick entry
						if (popover.quickSave) {
							quickSave(editEvent);
						}
						//Write our changes
						if (!popover.confirmed) {
							if (editEvent.saveDeferred) {
								editEvent.saveDeferred.then(function () {
									updateEvent(editEvent, null);
								});
							} else {
								updateEvent(editEvent, null);
							}
						}
					}
					// Remove an existing focus element
					const {id, type} = getFocusEvent();
					if (event._id === id && type === focusType) {
						clearFocusEvent();
					}
					if (onCloseResolve) {
						onCloseResolve();
					}
				},
				show: true,
			};
			//Prevent drag and drop while popover is open
			config.status.preventDrag = true;
			config.status.preventResize = true;
			//Open our popover and return popover methods

			const popoverResult = utilities.popover(popover, popoverTemplate);

			popoverResult.onClose = onClosePromise;

			if (callback) {
				callback(popoverResult);
			}
			return popoverResult;

			function checkFirstSegment(segments, startPosition) {
				for (var i = startPosition; i >= 0; i--) {
					if (
						fromNavigation === -1 &&
						(!eventNavigationMap[segments[0].event._id] ||
							segments[i].event._id !== segments[0].event._id)
					) {
						return false;
					} else if (
						(!fromNavigation || fromNavigation === 1) &&
						segments[i].event._id !== segments[0].event._id
					) {
						return false;
					}
				}
				return true;
			}

			function checkLastSegment(segments, startPosition) {
				for (var i = startPosition; i < segments.length; i++) {
					if (
						fromNavigation === 1 &&
						!eventNavigationMap[
							segments[segments.length - 1].event._id
						] &&
						segments[i].event._id !==
							segments[segments.length - 1].event._id
					) {
						return false;
					} else if (
						(!fromNavigation || fromNavigation === -1) &&
						segments[i].event._id !==
							segments[segments.length - 1].event._id
					) {
						return false;
					}
				}
				return true;
			}
		}

		function setFocusEvent(
			id,
			type,
			parentElementQuery,
			focusElement,
			forceEqualSides
		) {
			focusedEvent.id = id;
			focusedEvent.type = type;
			focusedEvent.parentElementQuery = parentElementQuery;
			focusedEvent.focusElement = focusElement;
			focusedEvent.forceEqualSides = forceEqualSides;
		}

		function getFocusEvent() {
			return focusedEvent;
		}

		function clearFocusEvent() {
			const {type, parentElementQuery, focusElement} = getFocusEvent();

			const parent = document.querySelector(parentElementQuery);
			if (!parent) {
				return;
			}

			if (type === 'map') {
				const outerParent = parent.parentElement.parentElement;
				if (outerParent) {
					outerParent.classList.remove('focus-selected-outer-parent');
				}
			}

			parent.classList.remove('focus-selected-parent');

			if (focusElement) {
				focusElement.remove();
			}
			focusedEvent = {};
		}

		function focusEvent(event, type, parentElementQuery, forceEqualSides) {
			if (!event || !parentElementQuery) {
				return;
			}

			const parent = document.querySelector(parentElementQuery);
			if (!parent) {
				return;
			}

			if (type === 'map') {
				const outerParent = parent.parentElement.parentElement;
				if (outerParent) {
					outerParent.classList.add('focus-selected-outer-parent');
				}
			}

			const offset = 6;
			const parentHeight = parent.offsetHeight;
			const parentWidth = parent.offsetWidth;
			const focusSize = Math.max(parentHeight, parentWidth) + 6;
			const highlightElement = document.createElement('div');
			highlightElement.classList.add('focus-selected');
			highlightElement.style.height = forceEqualSides
				? `${focusSize + offset}px`
				: `${parentHeight + offset}px`;
			highlightElement.style.width = forceEqualSides
				? `${focusSize + offset}px`
				: `${parentWidth + offset}px`;
			parent.appendChild(highlightElement);
			parent.classList.add('focus-selected-parent');

			setFocusEvent(
				event._id,
				type,
				parentElementQuery,
				highlightElement,
				forceEqualSides
			);

			return highlightElement;
		}

		function undoEventChange(
			type,
			message,
			event,
			editEvent,
			revertFunc,
			revertObject,
			callback,
			options
		) {
			var config = seedcodeCalendar.get('config');

			var multiSelect = seedcodeCalendar.get('multiSelect');

			var revertMessage =
				'<span class="message-icon-separator success">' +
				'<i class="fa fa-lg fa-check"></i>' +
				'</span>' +
				'<span translate>' +
				message +
				'</span>' +
				'<span class="message-separator"> | </span>' +
				'<span translate>Undo</span>' +
				'<span class="message-icon-separator" style="opacity: 0.8;"><i class="fa fa-lg fa-undo"></i></span>';
			//temporarily removing undo option if we're deleting a repetitons(s).
			if (
				!config.suppressEditEventMessages &&
				!multiselectUpdateInProgress
			) {
				if (
					event.recurringEventID &&
					editEvent &&
					editEvent.schedule.source.repeatingConfig &&
					editEvent.schedule.source.repeatingConfig()
				) {
					revertMessage =
						'<span class="message-icon-separator success">' +
						'<i class="fa fa-lg fa-check"></i>' +
						'</span>' +
						'<span translate>' +
						message +
						'</span>';
					utilities.showMessage(
						revertMessage,
						0,
						config.undoTimeout,
						null,
						null
					);
				} else {
					saveRevertFunction(runFunction, type, [event.eventID]);
					utilities.showMessage(
						revertMessage,
						0,
						config.undoTimeout,
						null,
						runRevertFunction
					);
				}
			}

			function runFunction() {
				if (!options) {
					options = {};
				}
				options.isUndo = true;

				if (type === 'deleted') {
					revertDelete(event, editEvent, callback, options);
				} else {
					revertEventChange(
						event,
						editEvent,
						revertFunc,
						revertObject,
						callback,
						options
					);
				}
			}
		}

		//Revert an edited event
		function revertEventChange(
			event,
			editEvent,
			revertFunc,
			revertObject,
			callback,
			options
		) {
			if (event.eventID && !revertFunc) {
				if (!editEvent) {
					editEvent = {};
				}
				for (var property in revertObject) {
					editEvent[property] = revertObject[property];
				}
				//Subtract day from edit event because this object doesn't use exclusive dates
				if (editEvent.allDay && editEvent.end) {
					editEvent.end.subtract(1, 'day');
				}
			}

			if (!options) {
				options = {};
			}
			options.endShifted = false;

			getEventChanges(event, editEvent, revertFunc, callback, options);
		}

		//Revert a deleted event
		function revertDelete(event, editEvent, callback, options) {
			var revertEvent = {};
			var revertEditEvent = {};

			if (options.deleteInstances) {
				//we need to treat the deletion of a repeating event diffently
				if (
					options.deleteInstances === 'instance' ||
					options.deleteInstances === 'future'
				) {
					//restore this instance to the source event rule
				} else if (options.deleteInstances === 'all') {
					//restore source event
				}
			} else {
				for (var property in editEvent) {
					revertEditEvent[property] = editEvent[property];
				}

				revertEditEvent.removed = utilities.stringToID(
					event.eventID + event.schedule.id
				);
				addEvent(revertEditEvent);

				//Get all of our events
				var events = seedcodeCalendar
					.get('element')
					.fullCalendar('clientEvents');

				//Find the newly added event so we can reference it
				for (var i = 0; i < events.length; i++) {
					if (
						events[i].removed ===
						utilities.stringToID(event.eventID + event.schedule.id)
					) {
						revertEvent = events[i];
						break;
					}
				}
				revertEvent.schedule = revertEditEvent.schedule;

				//IF the event was previously removed and restored via undo make sure to reset removed status
				revertEvent.removed = false;

				options.endShifted = false;
				//remove our eventIDs so a new event is created
				if (revertEvent.eventID) {
					delete revertEvent.eventID;
				}
				if (revertEditEvent.eventID) {
					delete revertEditEvent.eventID;
				}
				//remove time properties as they may not be current
				if (revertEvent.timeStart) {
					delete revertEvent.timeStart;
				}
				if (revertEditEvent.timeStart) {
					delete revertEditEvent.timeStart;
				}
				if (revertEvent.timeEnd) {
					delete revertEvent.timeEnd;
				}
				if (revertEditEvent.timeEnd) {
					delete revertEditEvent.timeEnd;
				}
				getEventChanges(
					revertEvent,
					revertEditEvent,
					null,
					callback,
					options
				);
			}
		}

		function confirmDeleteModal(editEvent, callback) {
			var values = {
				editEvent: editEvent,
				callback: callback,
			};
			var config = {
				controller: 'EventCtrl',
				target: '',
				container: '#calendar-container',
				type: 'modal', // modal or popover
				reserveWidth: '',
				positionX: 'auto',
				positionY: 'auto',
				direction: 'auto',
				data: values,
				dataTitle: 'edit',
				destroy: true,
				width: 600,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: function (edit) {
					//need to modify the rule of the original event if this.all or this.future selected
					var options = {
						future: this.future,
						all: this.all,
						confirmed: this.confirmed,
					};
					deleteEvent(editEvent, callback, options);
				},
				show: true,
			};
			utilities.popover(
				config,
				'<div ng-include="\'app/event/confirm-delete.html\'"></div>'
			);
		}

		function confirmSaveModal(
			event,
			editEvent,
			revertFunc,
			dialogCancelFunc,
			callback,
			changed
		) {
			var values = {
				event: event,
				editEvent: editEvent,
				revertFunc: revertFunc,
				dialogCancelFunc: dialogCancelFunc,
				callback: callback,
				changed: changed,
			};

			var config = {
				controller: 'EventCtrl',
				target: '',
				container: '#calendar-container',
				type: 'modal', // modal or popover
				reserveWidth: '',
				positionX: 'auto',
				positionY: 'auto',
				direction: 'auto',
				data: values,
				dataTitle: 'edit',
				destroy: true,
				width: '600px',
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: function (edit) {
					var options = {
						revert: this.revert,
						showConfirmation: this.showConfirmation,
						dialogRevertFunction:
							edit.revertFunc || this.dialogCancelFunc,
						endShifted: false,
						allRepetitions: this.all,
						futureRepetitons: this.future,
					};
					getEventChanges(
						edit.event,
						edit.editEvent,
						edit.revertFunc,
						edit.callback,
						options
					);
				},
				show: true,
			};
			var repeatingConfig =
				editEvent && editEvent.schedule.source.repeatingConfig
					? editEvent.schedule.source.repeatingConfig()
					: false;
			var fullModal =
				editEvent && repeatingConfig && !repeatingConfig.createOnly
					? true
					: false;
			var template = fullModal
				? '<div ng-include="\'app/event/confirm-save.html\'"></div>'
				: '<div ng-include="\'app/event/confirm-drop.html\'"></div>';
			utilities.popover(config, template);
		}

		function refreshEditPopover(editEvent) {
			$timeout(() => {
				assignEventColor(
					editEvent.event,
					editEvent.status,
					editEvent.schedule
				);
			}, 0);
		}

		function viewDay(date) {
			var calendarElement = seedcodeCalendar.get('element');
			$timeout(function () {
				calendarElement.fullCalendar('changeView', 'basicDay');
				calendarElement.fullCalendar('gotoDate', date);
			}, 0);
		}

		function processScheduleItem(schedule, scheduleItem) {
			return typeof scheduleItem === 'function'
				? scheduleItem(schedule)
				: scheduleItem;
		}

		function cleanSchedule(schedule, sourceTemplate) {
			//We have yet to match our source with the schedule here so cannot reference any source or source type data yet
			var defaults = seedcodeCalendar.get('defaults');
			var config = seedcodeCalendar.get('config');
			var eventDictionary = seedcodeCalendar.get('eventDictionary');
			var fieldMap = {};
			var fieldMapOverride = {};
			var customFieldMapLookup = {};
			var fieldMapLookup = {};

			// Check for config as it may not exist if switching to admin settings in the middle of loading
			if (!config) {
				return;
			}

			//apply source settings (applies defaults and any mutations)
			manageSettings.applySourceItem(schedule, sourceTemplate.settings);

			//Build timesatmp formatas
			schedule.fileTimestampFormat = sourceTemplate.useISOTimestamp
				? ''
				: schedule.fileDateFormat + ' ' + 'HH:mm';

			//Set any default unused map items
			if (sourceTemplate.unusedMap) {
				if (!schedule.unusedMap) {
					schedule.unusedMap = {};
				}
				for (var property in sourceTemplate.unusedMap) {
					schedule.unusedMap[property] = true;
				}
			}

			// Set unscheduled to unused map if it is not enabled for this source
			if (!schedule.allowUnscheduled) {
				if (!schedule.unusedMap) {
					schedule.unusedMap = {};
				}
				schedule.unusedMap.unscheduled = true;
			}

			//Set any default allow html map items
			if (sourceTemplate.allowHTMLMap) {
				if (!schedule.allowHTMLMap) {
					schedule.allowHTMLMap = {};
				}
				for (var property in sourceTemplate.allowHTMLMap) {
					schedule.allowHTMLMap[property] = true;
				}
			}

			//Set any default hidden field map items
			if (sourceTemplate.hiddenFieldMap) {
				if (!schedule.hiddenFieldMap) {
					schedule.hiddenFieldMap = {};
				}
				for (var property in sourceTemplate.hiddenFieldMap) {
					schedule.hiddenFieldMap[property] = true;
				}
			}

			//Set any default ready only field map items
			if (sourceTemplate.readOnlyFieldMap) {
				if (!schedule.readOnlyFieldMap) {
					schedule.readOnlyFieldMap = {};
				}
				for (var property in sourceTemplate.readOnlyFieldMap) {
					schedule.readOnlyFieldMap[property] = true;
				}
			}

			if (sourceTemplate.useDefaultFieldMap) {
				fieldMap = JSON.parse(JSON.stringify(eventDictionary));

				if (schedule.fieldMap) {
					fieldMapOverride =
						JSON.parse(JSON.stringify(schedule.fieldMap)) || {};
				}

				for (var property in sourceTemplate.fieldMap) {
					fieldMap[property] =
						fieldMapOverride[property] ||
						sourceTemplate.fieldMap[property].defaultValue;
				}
			} else {
				if (schedule.fieldMap) {
					fieldMap = JSON.parse(JSON.stringify(schedule.fieldMap));
				}

				for (var property in sourceTemplate.fieldMap) {
					if (
						!processScheduleItem(
							schedule,
							sourceTemplate.fieldMap[property].visible
						) &&
						(!sourceTemplate.fieldMap[property].allowedSchedules ||
							sourceTemplate.fieldMap[property].allowedSchedules[
								schedule.objectName
							])
					) {
						fieldMap[property] =
							sourceTemplate.fieldMap[property].defaultValue;
					} else if (
						processScheduleItem(
							schedule,
							sourceTemplate.fieldMap[property].required
						) &&
						processScheduleItem(
							schedule,
							sourceTemplate.fieldMap[property].visible
						) &&
						!fieldMap[property]
					) {
						fieldMap[property] =
							sourceTemplate.fieldMap[property].defaultValue;
					}
				}

				// Set sort and other metadata specified in config
				// Example of config object
				// var sortCalendars = {
				// 	<scheduleID>: {
				//
				// 	}
				// }
				// var sortCalendars = [
				// 	{
				// 		calendarID: 'optional id',
				// 		sourceTypeID: '8',
				// 		sortDireciton: 'ascending', // or descending
				// 		sortField: '',
				// 	},
				// ];
			}

			// Create field map lookup
			for (var property in fieldMap) {
				if (property !== 'title') {
					fieldMapLookup[fieldMap[property]] = property;
				}
			}

			//Add custom fields to field map
			var customFields = schedule.customFields;

			if (customFields) {
				for (var property in customFields) {
					customFieldMapLookup[customFields[property].field] =
						property;
					fieldMap[property] = customFields[property].field;
				}
			}

			// Set additional properties on actions
			// button actions
			if (schedule.customActions) {
				for (var property in schedule.customActions) {
					schedule.customActions[property].category = 'button';
				}
			}
			// event actions
			if (schedule.eventActions) {
				for (var property in schedule.eventActions) {
					schedule.eventActions[property].category = 'event';
				}
			}
			schedule.customFieldMapLookup = customFieldMapLookup;
			schedule.fieldMap = fieldMap;
			schedule.fieldMapLookup = fieldMapLookup;

			//Set defaults
			if (!schedule.backgroundColor) {
				schedule.backgroundColor = defaults.backgroundColor;
			}

			schedule.textColor = assignTextColor(schedule.backgroundColor);
			// if (!schedule.textColor) {
			//   schedule.textColor = defaults.textColor;
			// }

			schedule.borderColor = defaults.borderColor;

			//Set calendar status
			if (!schedule.status) {
				schedule.status = {};
			}
			schedule.status.loading = false;

			if (schedule.enabled) {
				schedule.status.selected = true;
			} else {
				schedule.status.selected = false;
			}

			//Check if this has been selected as the default calendar for new events
			schedule.isPrimary =
				config.primaryCalendar && config.primaryCalendar === schedule.id
					? true
					: false;

			return schedule;
		}

		function assignTextColor(backgroundColor) {
			return utilities.generateTextColor(backgroundColor);
		}

		function assignEventColor(event, status, schedule) {
			//Status is an optional array of statuses meant to override the statuses in the event. Useful when evaluating colors when assigning a new status
			//Schedule is an optional schedule object meant to override the event schedule. Useful when evaluating colors when switching schedules.

			var config = seedcodeCalendar.get('config');
			var statuses = seedcodeCalendar.get('statuses') || [];
			var eventStatus = status ? status : event.status;

			schedule = schedule ? schedule : event.schedule;

			if (!config.isShare) {
				event.borderColor = config.defaultBorderColor;
				if (schedule.source.assignColor) {
					event.color =
						schedule.source.assignColor(event) ||
						schedule.backgroundColor ||
						config.defaultEventColor;
				} else {
					event.color =
						schedule.backgroundColor || config.defaultEventColor;
				}

				event.textColor = schedule.textColor || config.defaultTextColor;
				for (var iii = 0; iii < statuses.length; iii++) {
					if (
						statuses[iii].name !== config.noFilterLabel &&
						statuses[iii].name &&
						eventStatus[0] === statuses[iii].name
					) {
						event.color = statuses[iii].color;
						continue;
					}
				}
			}

			event.textColor = assignTextColor(event.color);
			return event;
		}

		function mutateHook() {
			if (!seedcodeCalendar.get('element')) {
				return false;
			}
			return true;
		}

		function cleanEvent(event, hasEventTitleCalc) {
			var config = seedcodeCalendar.get('config');
			var customFields = event.schedule.customFields;
			var fieldMap = event.schedule.fieldMap;
			var share;
			var shareSchedule;
			var classNames = [];

			if (!config) {
				return event;
			}

			// Set creation date to number
			var creationDate = event.created
				? new Date(event.created)
				: new Date();
			event.created = creationDate.getTime();

			if (event.start && !event.end) {
				event.end = moment(event.start);
			}

			//Set eventStatus to an object. This object is used for things like showing a popover on the event.
			if (!event.eventStatus) {
				event.eventStatus = {};
			}

			// Set map object if it doesn't exist
			if (!event.map) {
				event.map = {};
			}

			if (hasEventTitleCalc) {
				// Build title calc (if no title calc then concat titleEdit and description)
				event.title = utilities.eventTitleCalc(
					fieldMap.title,
					event,
					event.schedule
				);
			}

			// Fix broken or incomplete tags so they don't affect rendering (escape what can't be fixed)
			event.title = utilities.encodeBrokenTags(event.title);

			// Remove any div elements from the display title to avoid render conflicts
			if (
				event.title &&
				event.schedule.allowHTMLMap &&
				event.schedule.allowHTMLMap.description
			) {
				event.title = event.title
					.replace(/<\s*?div(\s*?)>/gi, '<span>') // Replace div with no styles or classes
					.replace(/<\s*?div(\s[\s\S]*?)>/gi, '<span$1>') // Replace div with styles or classes
					.replace(/<\s*\/\s*div\s*>/gi, '</span>');
			}

			// Modify properties if we are viewing a share
			if (config.isShare) {
				share = seedcodeCalendar.get('share');
				shareSchedule =
					share && share.schedules
						? share.schedules[
								utilities.stringToID(event.shareScheduleID)
							]
						: null;
				event.shareSchedule = shareSchedule;
			}

			//Process tags so they are formatted correctly
			processTags(event);

			//Adjust custom field data types from strings to appropriate format
			if (customFields) {
				assignCustomFields(event, customFields);
			}

			//Assign empty statuses to default
			if (!event.status || !event.status.length) {
				event.status = [''];
			}

			for (var i = 0; i < event.status.length; i++) {
				if (
					event.status[i] === '' ||
					event.status[i] === null ||
					event.status[i] === undefined
				) {
					event.status = [config.noFilterLabel];
				}
			}

			//Assign empty resources to default
			if (!event.resource || !event.resource.length) {
				event.resource = [''];
			}

			//Check if this is a default resource
			assignDefaultResource(event);

			//Clean event and assign colors based on status
			assignEventColor(event);

			//Assign event class based on calendar name
			if (event?.schedule?.name) {
				classNames.push(event.schedule.name.replace(/\W/g, ''));
			}
			if (event.unscheduled) {
				classNames.push('unscheduled');
			}

			event.className = classNames.join(' ');

			//Assign source type id to event
			event.sourceTypeID = event.schedule.sourceTypeID;

			//Assign optional parameters
			event.startEditable = event.schedule.editable;
			event.durationEditable = event.schedule.editable;
			event.isUnavailable = config.isShare
				? event.isUnavailable
				: event.schedule.isUnavailable;
			event.isMeasureOnly = config.isShare
				? event.isMeasureOnly
				: event.schedule.isMeasureOnly;
			event.isMapOnly = config.isShare
				? event.isMapOnly
				: event.schedule.isMapOnly;

			//Add event sort (will sort if all things are equal)
			// if (!event.sort) {
			// 	event.sort = utilities.stringToID(event.eventID, true);
			// }

			//Clean metadata only json parse if it is not an object, if it is an object we can leave it as is
			if (event.metadata && typeof event.metadata !== 'object') {
				try {
					event.metadata = JSON.parse(event.metadata);
				} catch (error) {
					event.metadata = null;
				}
			}

			return event;
		}

		function cleanEvents(events) {
			//Clean events and assign colors based on status
			for (var i = 0; i < events.length; i++) {
				cleanEvent(events[i]);
			}
			return events;
		}

		function assignDefaultResource(
			event,
			editEvent,
			schedule,
			checkDefault
		) {
			var config = seedcodeCalendar.get('config');

			if (!schedule) {
				schedule = event.schedule;
			}

			// Normalize isDefaultResource in case that data was saved to the event for some reason
			event.isDefaultResource = false;

			if (editEvent) {
				editEvent.isDefaultResource = false;
			}

			for (var i = 0; i < event.resource.length; i++) {
				if (
					event.resource[i] === '' ||
					event.resource[i] === null ||
					event.resource[i] === undefined ||
					(checkDefault && event.resource[i] === config.noFilterLabel)
				) {
					if (schedule.defaultResource) {
						event.isDefaultResource = true;
						event.resource[i] = schedule.defaultResource.name;
						if (editEvent) {
							editEvent.isDefaultResource = true;
							editEvent.resource[i] =
								schedule.defaultResource.name;
						}
					} else {
						event.resource[i] = config.noFilterLabel;
						if (editEvent) {
							editEvent.resource[i] = config.noFilterLabel;
						}
					}
				}
			}
		}

		function assignCustomFields(event, customFields) {
			for (var property in customFields) {
				if (customFields[property].mutateInput) {
					event[property] = customFields[property].mutateInput(
						event[property],
						customFields[property],
						event.schedule
					);
				}
				// if (customFields[property].allowMultiple) {
				//   if (!event[property] || !event[property].length) {
				//     event[property] = [];
				//   }
				//   else if (!Array.isArray(event[property])) {
				//     event[property] = [event[property]];
				//   }
				// }
				if (
					!event.hasOwnProperty(property) ||
					event[property] === undefined ||
					event[property] === ''
				) {
					//If our property doesn't contain any data continue as we can't modify an empty value
					continue;
				}

				if (customFields[property].formatas === 'number') {
					//Convert to number
					event[property] = parseFloat(event[property], 10);
					if (isNaN(event[property])) {
						event[property] = null;
					}
				} else if (customFields[property].formatas === 'checkbox') {
					//Convert to boolean
					event[property] =
						event[property] === 'true' || event[property] === true;
				} else if (customFields[property].formatas === 'date') {
					//We are currently using the native date format of the source. So don't convert to a standardized format
					// event[property] = moment(event[property], event.schedule.fileDateFormat).format('YYYY-MM-DD');
				}
			}
		}

		function customFieldOutputTransform(
			property,
			item,
			customFields,
			schedule,
			sourceMutate
		) {
			var config = seedcodeCalendar.get('config');
			var customField = customFields[property];
			var result = sourceMutate(item, customField, schedule);

			return result;
		}

		function removeNoFilterValue(arrayItems) {
			var config = seedcodeCalendar.get('config');

			for (var i = 0; i < arrayItems.length; i++) {
				if (arrayItems[i] === config.noFilterLabel) {
					arrayItems[i] = '';
				}
			}
		}

		function replaceWithNoFilterValue(arrayItems) {
			var config = seedcodeCalendar.get('config');

			for (var i = 0; i < arrayItems.length; i++) {
				if (arrayItems[i] === '') {
					arrayItems[i] = config.noFilterLabel;
				}
			}
		}

		function processTags(event) {
			if (event.tags) {
				if (!Array.isArray(event.tags)) {
					event.tags = event.tags.split('\n');
				}
				for (var i = 0; i < event.tags.length; i++) {
					if (event.tags[i] === 'dayback') {
						event.isDayback = true;
					}
				}
			}
		}

		function packageTagsOutput(tags) {
			return tags && Array.isArray(tags) ? tags.join('\n') : '';
		}

		function isFilterAssigned(items) {
			var config = seedcodeCalendar.get('config');
			for (var i = 0; i < items.length; i++) {
				if (items[i] && items[i] !== config.noFilterLabel) {
					return true;
				}
			}
			return false;
		}

		function addSchedule(schedule) {
			fullCalendarBridge.addSource(
				seedcodeCalendar.get('element'),
				schedule
			);
			return schedule;
		}

		function removeSchedule(schedule) {
			fullCalendarBridge.removeSource(
				seedcodeCalendar.get('element'),
				schedule
			);
			return schedule;
		}

		function refreshScheduleDisplay(schedule) {
			removeSchedule(schedule);
			addSchedule(schedule);
		}

		function packageOutput(outputData, forClipboard) {
			//Makes a fully formed package to send to our back end for parsing.

			//Test if our data is an array. We need to transform it to an array if it isn't
			outputData = Array.isArray(outputData) ? outputData : [outputData];

			var packageStart = '!~start~!';
			var packageEnd = '!~end~!';
			var outputSeparator = '!~!';
			var dataPackage = forClipboard
				? outputData.join(outputSeparator)
				: encodeURIComponent(outputData.join(outputSeparator));
			var separatorAssignments = forClipboard
				? ''
				: '&$separator=' +
					outputSeparator +
					'&$packageStart=' +
					packageStart +
					'&$packageEnd=' +
					packageEnd;

			return (
				packageStart +
				outputSeparator +
				dataPackage +
				outputSeparator +
				packageEnd +
				separatorAssignments
			);
		}

		function confirmEditCallback(eventCSV, parameters) {
			//Parameters are in the form of an array [calendar, calendarIO, event, clipboardBackup]
			//eventCSV can also include actions. So rather than csv single term actions ie. refresh
			var element = seedcodeCalendar.get('element');
			var event = parameters['event'];
			var schedule = parameters['schedule'];
			var editObject = parameters['editObject'];
			var showEvent = parameters['show'];
			var clipboardBackup = parameters['clipboardBackup'];
			var callback = parameters['callback'];
			var callbackParams = parameters['callbackParams'];

			var view = element.fullCalendar('getView');
			var createNew;
			var confirmedEvent;
			var schedules;
			var eventHasChanged;
			var csvEventSource;

			if (eventCSV === 'refresh') {
				//Refresh all calendar data
				element.fullCalendar('refetchEvents');
				return;
			}

			//Make sure we have a schedule to work with
			if (!schedule) {
				csvEventSource = csvData.csvToEventSource(eventCSV);
				schedules = seedcodeCalendar.get('schedules');
				for (var i = 0; i < schedules.length; i++) {
					if (
						schedules[i].sourceTypeID === 1 &&
						schedules[i].id == csvEventSource
					) {
						//Using == here as we may be comparing a number to a string
						schedule = schedules[i];
					}
				}
			}

			confirmedEvent = schedule.source.mutateEvents(
				eventCSV,
				schedule
			)[0];
			if (!confirmedEvent && event) {
				//Event was deleted
				//Remove event from view
				fullCalendarBridge.removeEvents(element, event);
			} else {
				//Do we have a fullcalendar event?
				if (!event) {
					event = {};
					createNew = true;
				}

				//Add the schedule and clean the event
				confirmedEvent.schedule = schedule;
				cleanEvent(confirmedEvent);

				//Convert to fullcalendar dates so we can compare and set to event later if necessary
				confirmedEvent.start = $.fullCalendar.moment(
					confirmedEvent.start
				);
				confirmedEvent.end = $.fullCalendar.moment(confirmedEvent.end);

				if (confirmedEvent.allDay) {
					confirmedEvent.start.stripTime(); //Use fullcalendar stripTime method
					confirmedEvent.start.stripZone();
					confirmedEvent.end.stripTime(); //Use fullcalendar stripTime method
					confirmedEvent.end.stripZone();
					if (confirmedEvent.allDay && !createNew) {
						confirmedEvent.end = confirmedEvent.end.add(1, 'days');
					}
				}

				//Check to see if this event has changed coming from FileMaker (did FileMaker perform any transformations)
				if (editObject) {
					eventHasChanged = applyReturnedEventChanges(
						confirmedEvent,
						event
					);
				} else {
					eventHasChanged = true;
				}
				//Check if the event has changed coming from the backend. Did the backend perform any transformations on the data that our frontend doesn't know about?
				if (eventHasChanged) {
					for (var property in confirmedEvent) {
						if (property !== 'source') {
							// scheduleEvent[property] = event[0][property];
							event[property] = confirmedEvent[property];

							//Convert dates to fullcalendar moments
							// if (property === "start" || property === "end") {

							//   //Convert to fullcalendar date
							//   // event[property] = $.fullCalendar.moment(event[property]);

							//   //Set all day items so we don't have any conversions
							//   if (event.allDay) {
							//     // event[property].stripTime(); //Use fullcalendar stripTime method
							//     // event[property].stripZone();

							//     event["_" + property] = event[property].clone();
							//     // event[property].set('hour', 0).set('minute', 0).set('second', 0);
							//     // event[property]._ambigTime = true;
							//     // event["_" + property] = $.fullCalendar.moment(event[property]);
							//   }

							// }
							if (property === 'allDay') {
								event._allDay = event.allDay;
							}
						}
					}

					// if (event.allDay && !createNew) {
					//   // event.end = event.end.add(1, 'days');
					// }

					//Update event color
					assignEventColor(event);
					//Update this event
					if (createNew) {
						//Make sure we write our filemaker eventID to the event since we aren't updating anything else.
						element.fullCalendar('renderEvent', event);
					} else {
						fullCalendarBridge.update(element, event);
					}
				}

				//We don't want this event to be part of our viewed events if it is out of our view
				if (
					event._id &&
					(event.start.isAfter(view.end) ||
						event.end.isBefore(view.start))
				) {
					element.fullCalendar('removeEvents', event);
				}
			}

			//Restore clipboard contents if we used the clipboard to send data
			if (clipboardBackup) {
				window.clipboardData.setData('Text', clipboardBackup);
			}
			if (callback) {
				callbackParams = callbackParams ? callbackParams : event;
				callback(event, callbackParams);
			}
		}
		function applyReturnedEventChanges(event, changes) {
			var modified;
			for (var property in changes) {
				//Check string, numbers, and booleans
				if (
					(typeof changes[property] === 'undefined' ||
						typeof changes[property] === 'string' ||
						typeof changes[property] === 'number' ||
						typeof changes[property] === 'boolean' ||
						(typeof changes[property] === 'object' &&
							changes[property] === null)) &&
					property !== 'dateStart' &&
					property !== 'dateEnd' &&
					property !== 'timeStart' &&
					property !== 'timeEnd'
				) {
					if (event[property] !== changes[property]) {
						modified = true;
						//event[property] = changes[property];
					}
				}

				//Check arrays
				else if (Array.isArray(changes[property])) {
					if (
						!utilities.arraysEqual(
							event[property],
							changes[property]
						)
					) {
						modified = true;
						//event[property] = changes[property];
					}
				}
				//Check moment objects
				else if (moment.isMoment(changes[property])) {
					if (!changes[property].isSame(event[property])) {
						modified = true;
						//event[property] = changes[property];
					}
				}
			}
			return modified;
		}

		function getEventFromServer(event, queryID, callback, parameters) {
			var schedule = event ? event.schedule : null;
			utilities.getFileOnLoad(
				queryID,
				'sc_watcher.txt',
				'sc_update_event.txt',
				confirmEditCallback,
				{
					event: event,
					schedule: schedule,
					clipboardBackup: false,
					callback: callback,
					callbackParams: parameters,
				}
			);
		}

		function getEventChanges(
			event,
			editEvent,
			revertFunc,
			callback,
			options,
			providedChanges
		) {
			var source;
			var config = seedcodeCalendar.get('config');
			var fieldMap = editEvent
				? editEvent.schedule.fieldMap
				: event.schedule.fieldMap;
			var revertObject = {};
			var allowUndo =
				!!event.eventID &&
				(!options || (options && !options.preventUndo));
			var changes;
			var toDelete = [];

			var multiSelect = seedcodeCalendar.get('multiSelect');

			//Initialize options
			if (!options) {
				options = {};
			}

			//we've already mutated our event and updated full calendar,
			//so go straight to the callback for toast and share updates
			//doing here as we want a revert object for our undo
			if (options.runCallback) {
				revertObject = options.revertObject;
				runCallback(event);
				return;
			}

			//If this is an undo and a valid revertFunc exists
			if (options.isUndo && revertFunc) {
				//Create before drop so we can compare reverted changes
				event.beforeDrop = beforeDrop(event);
				revertFunc(function () {
					assignEventColor(event);
				});
				revertFunc = null;
			}

			if (options.endShifted !== false) {
				options.endShifted = true;
			}

			changes = providedChanges
				? providedChanges
				: editEvent
					? eventChanged(
							editEvent,
							editEvent.event,
							options.endShifted
						)
					: eventChanged(event, event.beforeDrop, true);
			//Check if our event was dragged and has a before drop object. We can pull things that weren't changed this way.
			if (event.beforeDrop && changes) {
				for (var property in changes) {
					if (property === 'start' || property === 'end') {
						if (
							event.beforeDrop[property] &&
							event.beforeDrop[property].isSame(
								changes[property]
							) &&
							event.beforeDrop.allDay === changes.allDay
						) {
							delete changes[property];
						}
					} else if (property === 'resource') {
						if (
							JSON.stringify(event.beforeDrop[property]) ===
							JSON.stringify(changes[property])
						) {
							delete changes[property];
						} else if (
							event.beforeDrop.isDefaultResource &&
							!options.isUndo
						) {
							changes.isDefaultResource = false;
							event.isDefaultResource = false;
						}
					} else {
						if (event.beforeDrop[property] === changes[property]) {
							delete changes[property];
						}
					}
				}
			}

			// Update read only fields
			const readOnlyFieldMap = editEvent
				? editEvent.schedule.readOnlyFieldMap
				: event.schedule.readOnlyFieldMap;
			for (let property in changes) {
				if (readOnlyFieldMap && readOnlyFieldMap[property]) {
					delete changes[property];
				}
			}

			// Check if there are no changes and this is a drag operation
			// This is used to accomodate read only fields
			if (!Object.keys(changes).length) {
				changes = false;
				if (event.beforeDrop && revertFunc) {
					revertFunc();
				}
			}

			//validate for partially filled FileMakerJS
			if (event.schedule.sourceTypeID === 8 && changes) {
				for (var change in changes) {
					var result = true;
					for (var field in fieldMap) {
						if (field === change && change !== 'allDay') {
							//needs to be mapped unless it's allDay
							if (!fieldMap[change]) {
								//not mapped and not all Day
								result = false;
								break;
							}
						}
					}
					if (!result) {
						var message =
							"This event can't be saved because the field mapping has not been completed for " +
							event.schedule.name +
							'.<span class="message-icon"><i class="fa fa-question-circle fa-lg dbk_icon_help" style="display: inline-block; vertical-align: middle;"></i></span>';
						utilities.showMessage(
							message,
							0,
							7000,
							'error',
							errorFunction,
							false
						);
						return;
					}
				}
			}
			function errorFunction() {
				utilities.help('Field Mapping', '136-field-mapping');
			}
			//end validation for FilemakerJS

			//Mark event as modified - We set this back to false after render
			if (!event.eventStatus) {
				event.eventStatus = {};
			}
			event.eventStatus.wasModified = true;
			event.eventStatus.isCustomAction = options.isCustomAction;

			//Check if we want to revert the event (from dialog selection) dragged events will revert popover saves haven't been written yet so just return
			if (options.revert) {
				if (options.dialogRevertFunction) {
					options.dialogRevertFunction();
				}
				runCallback(false);
				return;
			}
			//Check if we need to display a confirm dialog
			if (options.showConfirmation && changes) {
				confirmSaveModal(
					event,
					editEvent,
					revertFunc,
					null,
					callback,
					changes
				);
				return;
			}

			//Add schedule to revert object
			revertObject.schedule =
				event.eventStatus && event.eventStatus.revertObject
					? event.eventStatus.revertObject.schedule
					: event.schedule;
			//If schedule has changed update that data
			if (editEvent && event.schedule.id !== editEvent.schedule.id) {
				//ToDo: Should we put a source type specific function here for dealing with calendar changes?
				event.schedule = editEvent.schedule;
				event.colorId = null;
			}

			//Set revert object, used for undo and error recovery
			for (var property in fieldMap) {
				//Set revert object data
				if (property === 'start' || property === 'end') {
					revertObject[property] = event.eventStatus?.revertObject?.[
						property
					]
						? event.eventStatus.revertObject[property].clone()
						: event[property]
							? event[property].clone()
							: event[property];
				} else if (Array.isArray(event[property])) {
					revertObject[property] =
						event.eventStatus && event.eventStatus.revertObject
							? event.eventStatus.revertObject[property].slice(0)
							: event[property].slice(0);
				} else {
					revertObject[property] =
						event.eventStatus && event.eventStatus.revertObject
							? event.eventStatus.revertObject[property]
							: event[property];
				}
			}

			//Reset any potential revert objects stored in the event
			if (
				event.eventStatus &&
				event.eventStatus.revertObject &&
				(!options || (options && !options.isCustomAction))
			) {
				event.eventStatus.revertObject = null;
			}

			//If there are no changes to apply then we should exit
			if (!changes) {
				return;
			}

			//Save original changes to options for use in multiselect functions
			options.originalChangesObject = {};
			for (var property in changes) {
				options.originalChangesObject[property] = changes[property];
			}

			//Apply changes to event object if we are saving from popover
			if (editEvent) {
				applyEventChanges(event, editEvent, changes);
			}

			//Update event color
			assignEventColor(event);

			//Update resource if we are trying to update to default - usually part of undo
			if (
				changes &&
				changes.hasOwnProperty('isDefaultResource') &&
				event.eventID
			) {
				if (changes.isDefaultResource) {
					changes.resource = [];
				} else {
					changes.resource = editEvent
						? editEvent.resource
						: event.resource;
				}
			}

			if (editEvent) {
				// Clean our event to correct color selection and process tags etc.
				cleanEvent(event);
			}

			//Get an event action if any exist
			var actionCallbacks = {
				confirm: function (action) {
					runSaveChanges();
				},
				cancel: function (action) {
					action.preventAction = true;
					options.isUndo = true;
					options.preventDefault = action.preventDefault;
					revertEventChange(
						event,
						editEvent,
						revertFunc,
						revertObject,
						null,
						options
					);
				},
			};
			var actionResult = runEventActions(
				'eventSave',
				editEvent ? editEvent : event,
				true,
				actionCallbacks,
				null,
				event.beforeDrop ? event.beforeDrop : revertObject,
				changes,
				options.isUndo
			);
			//Return if the action result doesn't return true as we are preventing the default action

			if (editEvent) {
				//Write all changes back to full calendar event
				fullCalendarBridge.update(
					seedcodeCalendar.get('element'),
					event
				);
			}

			if (!actionResult || options.preventDefault) {
				//Clean up before drop before return
				if (event.beforeDrop) {
					options.beforeDrop = event.beforeDrop;
					delete event.beforeDrop;
				}

				return event;
			}
			//Run the save routine
			runSaveChanges();

			function runSaveChanges() {
				//Execute data source function to write back to backend
				if (options.allRepetitions) {
					event.schedule.source.updateSourceEvent(
						event,
						revertObject,
						revertFunc,
						changes,
						runCallback
					);
				} else if (options.futureRepetitons && editEvent) {
					event.schedule.source.updateRepeatingEventUntil(
						event,
						revertObject.start.clone().subtract('days', 1),
						futureEventsCallback
					);
				} else {
					event.schedule.source.editEvent(
						event,
						revertObject,
						revertFunc,
						changes,
						options.editID,
						runCallback
					);
				}

				return event;
			}

			function futureEventsCallback(data) {
				if (data.firstInstance) {
					//we've tried to do a future update on the first instance. Run as all instead.
					event.schedule.source.updateSourceEvent(
						event,
						revertObject,
						revertFunc,
						changes,
						runCallback
					);
					return;
				}
				//mark events to be removed
				var element = seedcodeCalendar.get('element');
				var events = element.fullCalendar('clientEvents');
				for (var i = 0; i < events.length; i++) {
					if (
						events[i].start.format('YYYYMMDD') >
							editEvent.event.start.format('YYYYMMDD') &&
						events[i].recurringEventID === event.recurringEventID &&
						events[i].eventID !== event.eventID
					) {
						toDelete.push(events[i]);
					}
				}

				//now create a new event
				event.destroyToday = true;
				event.eventID = null;
				event.recurringEventID = null;
				if (editEvent) {
					editEvent.recurrence = data.recurrence;
					if (editEvent.allDay) {
						editEvent.end.add(1, 'days');
					}
				}
				editEvent.description = '';

				const changes = duplicateEvent(
					editEvent,
					editEvent.schedule,
					true
				);
				// Add back recurring id as it isn't included in duplicate
				changes.recurringEventID = editEvent.recurringEventID;

				event.schedule.source.editEvent(
					event,
					revertObject,
					revertFunc,
					changes,
					null,
					runCallback,
					event.schedule,
					null,
					true
				);
			}

			function runCallback(event) {
				var element = seedcodeCalendar.get('element');
				//remove any dead repetitons
				for (var i = 0; i < toDelete.length; i++) {
					element.fullCalendar('removeEvents', toDelete[i]);
					toDelete[i].removed = true;
				}

				var shareData = [];
				var shareDataForDepricated = [];
				var hasDepricatedShareEventID;
				var hasShareEventID;

				if (event && !event.error) {
					//If this has a shared event attached and is part of an undo process (currently only used for deleting events)
					if (options && options.isUndo && options.isShared) {
						if (options.eventShares && options.eventShares.length) {
							for (
								var i = 0;
								i < options.eventShares.length;
								i++
							) {
								daybackIO.setShareData(
									options.eventShares[i].id + '/events',
									utilities.generateEventID(
										event.schedule.sourceTypeID,
										event.schedule.id,
										event.eventID,
										options.eventShares[i].depricatedEventID
									),
									{id: event.eventID},
									false,
									null,
									null
								);
								if (options.eventShares[i].depricatedEventID) {
									shareDataForDepricated.push({
										id: options.eventShares[i].id,
									});
									hasDepricatedShareEventID = true;
								} else {
									shareData.push({
										id: options.eventShares[i].id,
									});
									hasShareEventID = true;
								}
							}
						}
						if (hasDepricatedShareEventID) {
							event.schedule.source.editSharedEvent(
								[event],
								null,
								null,
								null,
								null,
								null,
								{share: shareDataForDepricated},
								null,
								true
							);
						}
						if (hasShareEventID) {
							event.schedule.source.editSharedEvent(
								[event],
								null,
								null,
								null,
								null,
								null,
								{share: shareData},
								null
							);
						}
					} else {
						//Check if event is shared and update accordingly
						// Update with new event key (sourceType - source - event)
						updateShareEvent();

						// Update with old event key (sourceType - event)
						updateShareEvent(true);
					}

					//Show an undo message
					if (!options.isUndo && allowUndo) {
						undoEventChange(
							'saved',
							'Saved',
							event,
							editEvent,
							revertFunc,
							revertObject,
							callback,
							options // Pass options object because we can use that elsewhere to determine if called from a custom action
						);
					} else if (!options.isUndo) {
						//Show save message that the save was successful even though we don't allow undo here
						var savedMessage =
							'<span class="message-icon-separator success">' +
							'<i class="fa fa-lg fa-check"></i>' +
							'</span>' +
							'<span translate>' +
							'Saved' +
							'</span>';
						if (
							!config.suppressEditEventMessages &&
							!multiselectUpdateInProgress
						) {
							utilities.showMessage(
								savedMessage,
								0,
								5000,
								null,
								null
							);
						}
					} else if (options.isUndo) {
						// For fisher investments (Jason made me do it!!) this is temporary and should be changed to an after event saved action later
						seedcodeCalendar.init('undo-completed', true);
					}
				}

				if (editEvent && editEvent.event) {
					// Update the edit event just in case the popover is staying open when done saving
					updateEditEvent(editEvent.event, editEvent);
				}

				//Run any callback that was passed
				if (callback) {
					callback(
						event,
						changes,
						event && event.beforeDrop
							? event.beforeDrop
							: revertObject,
						revertFunc,
						options
					);
				}

				// Clear the before drop data so subsequent edits don't assume drag and drop
				if (event && event.beforeDrop) {
					delete event.beforeDrop;
				}

				// Clear any evenStatus properties that were temporary
				if (event?.eventStatus?.create) {
					delete event.eventStatus.create;
				}

				function updateShareEvent(depricatedEventID) {
					event.schedule.source.getSharedEvents(
						null,
						null,
						null,
						function (sharedEvent) {
							//If the event is currently shared then update that shared event so it is properly synced
							if (sharedEvent && sharedEvent.length) {
								event.schedule.source.editSharedEvent(
									[event],
									null,
									null,
									null,
									null,
									null,
									null,
									null,
									depricatedEventID
								);
							}
						},
						event.schedule,
						{getSharedEvents: true},
						utilities.generateEventID(
							event.schedule.sourceTypeID,
							event.schedule.id,
							event.eventID,
							depricatedEventID
						)
					);
				}
			}
		}

		function applyEventChanges(event, editEvent, changes) {
			var config = seedcodeCalendar.get('config');
			for (var property in changes) {
				//Only apply the following changes if we are in a popover
				//We just want the source name for our backend as the source object contains both additional attributes as well as the list of events
				if (property === 'source') {
					// if (event.newSource) {
					//   output.push(event.newSource.name);
					// }
					// else {
					//   output.push(event.source.name);
					// }
				}

				//Convert dates to fullcalendar moments
				if (
					changes[property] !== null &&
					(property === 'start' || property === 'end')
				) {
					//Convert to fullcalendar date
					event[property] = $.fullCalendar.moment(
						changes[property].clone().toArray()
					); //Rezone so we are never passing in a non offset time

					//Set all day items so we don't have any conversions
					if (editEvent.allDay) {
						event[property].stripTime(); //Use fullcalendar stripTime method
						event[property].stripZone();

						event['_' + property] = event[property].clone();
					}
				} else if (property === 'resource') {
					//Normalize resource to prevent empty value (this way we render on none colum immediately)
					if (!changes[property] || changes[property].length === 0) {
						changes[property] = [config.noFilterLabel];
					}
					event[property] = changes[property];
				} else {
					event[property] = changes[property];
				}

				// Apply changes to geocode if mapped to another field
				// ToDo: Should we do this for every field if they are mapped the same?
				const fieldMap = event.schedule.fieldMap;
				const unusedMap = event.schedule.unusedMap;
				if (
					property !== 'geocode' &&
					!changes.hasOwnProperty('geocode') &&
					fieldMap.geocode &&
					!unusedMap.gecode &&
					fieldMap[property] === fieldMap.geocode
				) {
					changes.geocode = changes[property];
					event.geocode = changes.geocode;
				}
			}
			//Update title property so we see something change immediately on the event
			if (changes.hasOwnProperty('titleEdit')) {
				event.title = event.titleEdit;
			}

			//If we are going from all day to timed or vise-versa we don't want to revert to default duration.
			if (changes.hasOwnProperty('allDay')) {
				//Set all day items so we don't have any conversions
				event._allDay = event.allDay;
			}

			// Convert text string to geocode object
			const allowTextField = event.schedule.allowTextFieldMap?.geocode;
			if (
				allowTextField &&
				changes.geocode &&
				typeof event.geocode === 'string'
			) {
				const geocodeParts = event.geocode.split(',');
				event.geocode = {
					lat: parseFloat(geocodeParts[0]?.trim()),
					lng: parseFloat(geocodeParts[1]?.trim()),
				};
				if (isNaN(event.geocode.lat) || isNaN(event.geocode.lng)) {
					event.geocode = '';
				}
			}
		}

		//capture pre drag values
		function beforeDrop(event) {
			return backupEvent(event);
		}

		function backupEvent(event) {
			var outputEvent = {};
			var fieldMap = event.schedule.fieldMap;
			if (!event) {
				return false;
			}

			//Check for changed data. If there is no changed data we exit.
			for (var property in fieldMap) {
				//Test for items that are arrays
				if (Array.isArray(event[property])) {
					outputEvent[property] = event[property].slice(0);
				}
				//Test for times
				else if (
					event[property] &&
					(property === 'start' || property === 'end')
				) {
					outputEvent[property] = $.fullCalendar.moment(
						event[property]
					);
				}
				//Test for items that are objects
				else {
					outputEvent[property] = event[property];
				}
			}

			//Copy schedule
			outputEvent.schedule = event.schedule;

			return outputEvent;
		}

		function eventChanged(editEvent, event, endShifted) {
			//Referenced in event action documentation Do not modify output from changes object
			if (!editEvent || !event) {
				return false;
			}

			var modified;
			var timeChanged;
			var changes = {};
			var dateCompare = {};
			var schedule = editEvent ? editEvent.schedule : event.schedule;
			var fieldMap = schedule.fieldMap;
			var customFields = schedule.customFields;
			var config = seedcodeCalendar.get('config');

			if (editEvent.start) {
				dateCompare.start = moment(editEvent.start);
			}

			//Adjust our end date if we have an all day event
			if (editEvent.allDay && editEvent.end && !endShifted) {
				dateCompare.end = moment(editEvent.end).add(1, 'day');
			} else if (editEvent.end) {
				dateCompare.end = moment(editEvent.end);
			}

			//Check for changed data. If there is no changed dqta we exit.
			for (var property in fieldMap) {
				// Custom fields are broken out into individual fields so the object doesn't need to be evaluated for changes as it's just the definitions
				// Don't apply event change if the field is marked as read only
				if (
					property === 'customFields' ||
					(customFields &&
						customFields[property] &&
						customFields[property].readOnlyField)
				) {
					continue;
				}

				// Don't write back read only fields
				if (property === 'created' || property === 'eventURL') {
					continue;
				}
				//We shouldn't mark as changed if it is a new event and the value is blank --- Todo Add this so we can have read only fields
				//If it's a new event and the resource is set to something then mark as changed
				else if (
					!editEvent.eventID &&
					property === 'resource' &&
					editEvent[property] &&
					editEvent[property].length === 1
				) {
					if (
						editEvent[property][0] !== config.noFilterLabel &&
						!editEvent.isDefaultResource
					) {
						modified = true;
						changes[property] = editEvent[property];
					}
				}
				//Check if status is something than just the no filter label
				else if (
					!editEvent.eventID &&
					property === 'status' &&
					editEvent[property] &&
					editEvent[property].length === 1
				) {
					if (editEvent[property][0] !== config.noFilterLabel) {
						modified = true;
						changes[property] = editEvent[property];
					}
				}
				//Test for items that are arrays
				else if (Array.isArray(editEvent[property])) {
					if (!editEvent.eventID) {
						//We consider this modified if it's a new event and the field is not empty
						if (editEvent[property].length) {
							modified = true;
							changes[property] = editEvent[property];
						}
					} else if (
						utilities.arrayDiff(
							event[property],
							editEvent[property]
						).length > 0 ||
						(editEvent.removed && editEvent[property].length)
					) {
						modified = true;
						changes[property] = editEvent[property];
					}
				}
				//Test for times
				else if (property === 'start' || property === 'end') {
					if (!editEvent[property] && !event[property]) {
						continue;
					} else if (!event[property] && editEvent[property]) {
						modified = true;
						changes[property] = dateCompare[property];
						continue;
					}
					//Check to make sure there is an end date / time. If not let's move on
					if (
						property === 'end' &&
						!event.end &&
						dateCompare.end.isSame(editEvent.start)
					) {
						continue;
					} else if (property === 'end') {
						timeChanged = editEvent.allDay
							? !event[property].isSame(dateCompare.end, 'day')
							: !event[property].isSame(dateCompare.end);
					} else {
						timeChanged = editEvent.allDay
							? !event[property].isSame(
									editEvent[property],
									'day'
								)
							: !event[property].isSame(editEvent[property]);
					}
					if (
						!editEvent.eventID ||
						timeChanged ||
						(editEvent.allDay !== undefined &&
							editEvent.allDay !== event.allDay)
					) {
						modified = true;
						changes[property] = dateCompare[property];
					}
				}
				//Test for items that are not objects
				else if (
					(typeof editEvent[property] !== 'object' ||
						editEvent[property] === null ||
						editEvent[property] === undefined) &&
					property !== 'title'
				) {
					if (!editEvent.eventID) {
						//We consider this modified if it's a new event and the field is not empty
						if (
							editEvent[property] !== '' &&
							editEvent[property] !== undefined &&
							editEvent[property] !== null
						) {
							modified = true;
							changes[property] = editEvent[property];
						}
					} else if (event[property] !== editEvent[property]) {
						modified = true;
						changes[property] = editEvent[property];
					}
				}
				//Test for items that are objects
				else if (typeof editEvent[property] === 'object') {
					if (
						JSON.stringify(event[property]) !==
						JSON.stringify(editEvent[property])
					) {
						modified = true;
						changes[property] = JSON.parse(
							JSON.stringify(editEvent[property])
						);
					}
				}
			}

			//Check if our schedule has been changed
			if (
				!editEvent.eventID ||
				editEvent.schedule.id !== event.schedule.id
			) {
				modified = true;
				changes.eventSource = editEvent.schedule.id;
			}

			//If no changes are detected we just return as there is nothing to do here. Make sure to check if an eventID exists. Because if it doesn't it means the event hasn't been saved at all yet.
			if (!modified) {
				return false;
			} else {
				return changes;
			}
		}

		function saveRequiresConfirmation(event) {
			if (
				event.recurringEventID &&
				event.schedule.source.repeatingConfig &&
				event.schedule.source.repeatingConfig()
			) {
				return true;
			}
			return false;
		}

		function updateEvent(editEvent, callback) {
			var modified;
			var options = {};

			if (!editEvent || !editEvent.event || editEvent.event.removed) {
				if (callback) {
					callback(editEvent.event);
				}
				return false;
			}

			modified = eventChanged(editEvent, editEvent.event, false);
			options.endShifted = false; // Mark the end date as not timeshifted

			//Check if our schedule has been changed
			if (editEvent.schedule.id !== editEvent.event.schedule.id) {
				modified = true;
			}

			//Check if we are explicitely wanting to save changes
			if (
				editEvent.event.eventStatus &&
				editEvent.event.eventStatus.unsavedChanges
			) {
				modified = true;
				editEvent.event.eventStatus.unsavedChanges = false;
			}

			//If no changes are detected we just return as there is nothing to do here. Make sure to check if an eventID exists. Because if it doesn't it means the event hasn't been saved at all yet.
			if (!modified && editEvent.eventID) {
				if (callback) {
					callback(editEvent.event);
				}
				return false;
			}

			//Apply the changes
			getEventChanges(
				editEvent.event,
				editEvent,
				null,
				callback,
				options
			);
			return true;
		}

		function updateEventTime(event, revertFunc) {
			//Apply event changes
			var timestamp = Number(new Date().getTime());

			var options = {
				showConfirmation: saveRequiresConfirmation(event),
				editID: timestamp, //Add timestamp to options as we will pass this to the source edit function
			};

			//Get the edit queue for the current source so we can let the system know an event is getting edited
			var editQueue = event.schedule.source.getEventEditQueue();
			//Added event id to edit queue
			editQueue[event.eventID] = timestamp;

			options.endShifted = true;

			//Run event actions if any exist
			var actionCallbacks = {
				confirm: function (action) {
					getEventChanges(
						event,
						null,
						revertFunc,
						updateMultiSelectEvents,
						options
					);
				},
				cancel: function (action) {
					revertFunc();
					// Clear before drop if it exists since we won't run getEventChanges
					if (event.beforeDrop) {
						delete event.beforeDrop;
					}
				},
			};

			var actionResult = runEventActions(
				'beforeEventSave',
				event,
				true,
				actionCallbacks,
				null
			);
			var multiSelectInvalid = event.beforeDrop
				? checkForInvalidMultiselect(event, revertFunc)
				: false;
			//Return if the action result doesn't return true as we are preventing the default action
			if (!actionResult || multiSelectInvalid) {
				clearEventDragStatus(event);
				return;
			}

			if (event.schedule.unusedMap.end || !event.schedule.fieldMap.end) {
				var durationDays = event.end.days() - event.start.days();
				var durationMinutes = event.end.diff(event.start, 'minutes');
				if (
					(event.allDay && durationDays > 1) ||
					(!event.allDay &&
						!event.beforeDrop.allDay &&
						durationMinutes > 1)
				) {
					var message =
						'The source ' +
						event.schedule.name +
						' does not have "end" mapped, so you cannot change the duration of this item. You can change this by mapping "end" to a field in the source settings for ' +
						event.schedule.name +
						'.';
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Revert Changes',
						revertFunc
					);
					return;
				}
			}

			getEventChanges(
				event,
				null,
				revertFunc,
				updateMultiSelectEvents,
				options
			);
		}

		function clearEventDragStatus(event) {
			var config = seedcodeCalendar.get('config');
			config.status.isDragging = false;
			var editQueue = event.schedule.source.getEventEditQueue();
			//Clear dragging flag
			editQueue.dragging = null;
		}

		function duplicateEvent(event, destinationSchedule, dataCloneOnly) {
			var eventResult = {};
			var fieldMap = event.schedule.fieldMap;
			var schedules = seedcodeCalendar.get('schedules');

			for (var property in fieldMap) {
				//recordID is included as we use that for sources like fielamker server that have a seperate non user facing id
				// Don't include recurringEventID as we don't want to make duplicated events repeating
				if (
					event[property] &&
					property !== 'eventID' &&
					property !== 'recordID' &&
					property !== 'recurringEventID' &&
					property !== 'timeStart' &&
					property !== 'timeEnd' &&
					property !== 'hasAttachments' &&
					property !== 'schedule' &&
					property !== 'created'
				) {
					if (property === 'start' || property === 'end') {
						eventResult[property] = event[property]
							? event[property].clone()
							: null;
					} else if (
						event[property] &&
						(Array.isArray(event[property]) ||
							typeof event[property] === 'object')
					) {
						eventResult[property] = JSON.parse(
							JSON.stringify(event[property])
						);
					} else {
						eventResult[property] = event[property];
					}
				}
			}

			// Modify resource selection if the current event is using a default resource
			eventResult.isDefaultResource = event.isDefaultResource;
			if (eventResult.isDefaultResource) {
				eventResult.resource = [];
			}

			if (!dataCloneOnly && eventResult.allDay && eventResult.end) {
				eventResult.end.subtract(1, 'day');
			}

			if (destinationSchedule && destinationSchedule.id) {
				eventResult.schedule = destinationSchedule;
				eventResult.eventSource = destinationSchedule.id;
				createEventData(eventResult, destinationSchedule);
			} else {
				//Make sure we set the appropriate event source (scheduleID)
				eventResult.eventSource = event.eventSource;

				for (var i = 0; i < schedules.length; i++) {
					if (schedules[i].id === eventResult.eventSource) {
						eventResult.schedule = schedules[i];
					}
				}
			}

			if (!dataCloneOnly) {
				eventResult.eventStatus = {
					isClone: true,
				};
				renderNewEvent(eventResult);
			}
			return eventResult;
		}

		function renderNewEvent(event, useEventStatus) {
			if (!event.eventStatus) {
				event.eventStatus = {};
			}

			if (!useEventStatus) {
				event.eventStatus.showPopover = true;
				event.eventStatus.firstOpen = true;
				event.eventStatus.wasModified = false;
			}

			event.eventStatus.create = true;

			event.newSource = {
				editable: true,
				id: event.schedule.id,
				name: event.schedule.name,
			};

			//Run event actions if any exist
			var actionCallbacks = {
				confirm: function (action) {
					action.preventAction = true;
					renderNewEvent(event);
				},
				cancel: function (action) {
					//Some functionality can go here that represents a cancel callback
				},
			};
			var actionResult = runEventActions(
				'eventCreate',
				event,
				true,
				actionCallbacks,
				null
			);
			//Return if the action result doesn't return true as we are preventing the default action
			if (!actionResult) {
				return;
			}

			//Render the event to view and add to our schedule
			addEvent(event);
		}

		function addEvent(event) {
			cleanEvent(event);
			seedcodeCalendar.get('element').fullCalendar('renderEvent', event);
		}

		function createEvent(
			eventData,
			scheduleID,
			scheduleName,
			preventWarnings,
			renderEvent,
			callback,
			fromArray
		) {
			var config = seedcodeCalendar.get('config');
			var schedules = seedcodeCalendar.get('schedules');
			var schedule;
			var editEvent;
			var options;
			var errorMessage;
			var clientEvents;
			var event;
			var shown;

			var eventWarningThreshold = 200;

			if (!eventData || (!scheduleID && !scheduleName)) {
				errorMessage = 'Missing required data. Could not create event';
				utilities.showMessage(
					errorMessage,
					0,
					7000,
					'error',
					null,
					false
				);
				if (callback) {
					callback({error: {message: errorMessage}});
				}
				return;
			}

			for (var i = 0; i < schedules.length; i++) {
				if (
					schedules[i].id === scheduleID ||
					schedules[i].name === scheduleName
				) {
					schedule = schedules[i];
					break;
				}
			}

			// Schedule could not be found from supplied data
			if (!schedule) {
				errorMessage =
					'Could not find a matching calendar so the event was not created';
				utilities.showMessage(
					errorMessage,
					0,
					7000,
					'error',
					null,
					false
				);
				if (callback) {
					callback({error: {message: errorMessage}});
				}
				return;
			}

			// Schedule could not be found from supplied data
			if (!schedule.editable) {
				errorMessage =
					'The provided calendar is not editable and events cannot be created on it';
				utilities.showMessage(
					errorMessage,
					0,
					7000,
					'error',
					null,
					false
				);
				if (callback) {
					callback({error: {message: errorMessage}});
				}
				return;
			}

			if (Array.isArray(eventData)) {
				if (
					!preventWarnings &&
					eventData.length > eventWarningThreshold
				) {
					utilities.showModal(
						'Creating ' + eventData.length + ' Events',
						'Creating this many events will take some time and could result in errors due to rate limiting. It is not recommended to create this many events at one time. Would you still like to create these events anyway?',
						'Cancel',
						null,
						'Create',
						function () {
							createEventsFromArray(
								eventData,
								schedule.id,
								preventWarnings,
								renderEvent,
								callback
							);
						}
					);
					return;
				}

				createEventsFromArray(
					eventData,
					schedule.id,
					preventWarnings,
					renderEvent,
					callback
				);
				return;
			}

			if (!fromArray) {
				// Set config to prevent edit dialog messages
				config.passthroughEditErrors = true;
				config.suppressEditEventMessages = true;
			}

			if (eventData._id) {
				eventData = duplicateEvent(eventData, schedule, true);
			} else {
				createEventData(eventData, schedule);
			}
			eventData.start = $.fullCalendar.moment(eventData.start);
			eventData.end = $.fullCalendar.moment(eventData.end);
			eventData._start = eventData.start.clone();
			eventData._end = eventData.end.clone();
			eventData._allDay = eventData.allDay;
			eventData._id = utilities.generateUID();

			shown =
				renderEvent &&
				schedule.status &&
				schedule.status.selected &&
				(config.eventShown(eventData) || eventData.unscheduled);

			eventData.eventStatus = {
				firstOpen: true,
				showPopover: false,
				wasModified: false,
			};

			if (shown) {
				let clientEvents;
				renderNewEvent(eventData, true);
				// need to branch this for unscheduled
				if (eventData.unscheduled) {
					clientEvents = seedcodeCalendar
						.get('element')
						.fullCalendar('unscheduledClientEvents');
				} else {
					clientEvents = seedcodeCalendar
						.get('element')
						.fullCalendar('clientEvents');
				}

				// addEvent(eventData);
				for (var i = 0; i < clientEvents.length; i++) {
					if (clientEvents[i]._id === eventData._id) {
						event = clientEvents[i];
						break;
					}
				}
			} else {
				// Adjust end for all day since we are not rendering
				if (eventData.allDay && eventData.end) {
					eventData.end.add(1, 'day');
				}
				event = cleanEvent(eventData);
			}

			editEvent = createEditEvent(event, schedule.fieldMap, true);

			getEventChanges(
				editEvent.event,
				editEvent,
				null,
				resultCallback,
				options,
				null
			);

			function resultCallback(result) {
				var element;
				var hasError;
				var resultData = {};

				if (!result) {
					result = {
						error: {
							message:
								'The event could not be created because an error occurred',
						},
					};
				}

				resultData.event = result.error ? eventData : result;
				resultData.isShown = shown;
				if (result.error) {
					resultData.error = result.error;
				}

				if (!shown || result.error) {
					element = seedcodeCalendar.get('element');
					element.fullCalendar('removeEvents', event);
					event.removed = true;
				}

				if (!fromArray) {
					config.passthroughEditErrors = null;
					config.suppressEditEventMessages = null;
				}

				if (callback) {
					callback(resultData);
				}
			}
		}

		function createEventsFromArray(
			eventData,
			scheduleID,
			preventWarnings,
			renderEvent,
			callback
		) {
			var config = seedcodeCalendar.get('config');
			var resultSuccessCount = 0;
			var eventCount = eventData.length;
			var createResult = [];
			var hasError;
			var message;

			var waitTime = eventCount > 100 ? 1 : 0;

			config.passthroughEditErrors = true;
			config.suppressEditEventMessages = true;

			for (var i = 0; i < eventCount; i++) {
				runCreateEvent(eventData[i]);
			}

			function runCreateEvent(event) {
				window.setTimeout(function () {
					createEvent(
						event,
						scheduleID,
						null,
						preventWarnings,
						renderEvent,
						checkResult,
						true
					);
				}, waitTime * i);
			}

			function checkResult(result) {
				resultSuccessCount++;
				if (result && result.error) {
					hasError = true;
				}
				createResult.push(result);
				if (resultSuccessCount === eventCount) {
					config.passthroughEditErrors = null;
					config.suppressEditEventMessages = null;

					if (callback) {
						callback(createResult);
					}
				}
			}
		}

		function newEvent(
			date,
			allDay,
			eventTitle,
			breakoutData,
			isUnscheduled
		) {
			var config = seedcodeCalendar.get('config');
			var schedules = seedcodeCalendar.get('schedules');
			var schedulePosition;
			var firstEditablePosition;
			var scheduleTimeConflict;
			var primaryTimeConflict = true;
			var event = {};
			var errorMessage = 'You currently have no editable calendars';
			var translations = $translate.instant([
				'Could not create a new event because all breakout items are hidden',
			]);
			var availableBreakoutItems;
			var availableFilterItems;
			var hasBreakoutField;

			var breakoutSchedule;
			var unscheduledMatch;

			var horizonBreakoutField =
				config.defaultView.indexOf('Horizon') > -1
					? config.horizonBreakoutField
					: null;

			var noFilterLabel = config.noFilterLabel;
			var createAfterRender;

			// Show message for contact / project (how do we handle creating new on a specific calendar?)

			//Get primary schedule position
			for (var i = 0; i < schedules.length; i++) {
				if (isUnscheduled) {
					if (
						schedules[i].allowUnscheduled &&
						schedules[i]?.status?.selected
					) {
						schedulePosition = i;
						break;
					}
				} else if (
					horizonBreakoutField &&
					horizonBreakoutField === 'schedule' &&
					breakoutData &&
					breakoutData.value.name === schedules[i].name
				) {
					// Check position of target breakout schedule
					schedulePosition = i;
					if (!schedules[schedulePosition].editable) {
						utilities.showModal(
							'Read Only Calendar',
							'The calendar "' +
								schedules[schedulePosition].name +
								'" is read only. The event will be created on the first available editable calendar instead.',
							null,
							null,
							'OK',
							function () {
								newEvent(date, allDay, eventTitle, null);
							}
						);
						return;
					}

					break;
				} else if (schedules[i].isPrimary) {
					schedulePosition = i;
					break;
				}
			}
			if (!isUnscheduled) {
				//Set breakout data
				if (breakoutData) {
					// If dragging to "none" run new event without any breakout data
					if (breakoutData.value.name === noFilterLabel) {
						newEvent(date, allDay, eventTitle, null);
						return;
					}

					// Contact and Project not supported
					if (
						breakoutData.field === 'contactName' ||
						breakoutData.field === 'projectName'
					) {
						utilities.showModal(
							'Event will be created in "' +
								config.noFilterLabel +
								'" ',
							'Creating a new event on the "' +
								(config.breakout
									? config.breakout.label
									: breakoutData.field) +
								'" field is not currently supported. The new event will be created in "' +
								config.noFilterLabel +
								'" instead.',
							null,
							null,
							'OK',
							function () {
								newEvent(date, allDay, eventTitle, null);
							}
						);
						return;
					}

					// Set event data with breakout value if not in breakout by anything
					if (
						(!config.breakout || !horizonBreakoutField) &&
						breakoutData.field !== 'schedule' &&
						breakoutData.value.name !== noFilterLabel
					) {
						event[breakoutData.field] = [breakoutData.value.name];
					}

					//Check to see if we are in a resource view and the resource isn't shown. Select first shown resource if there is no match
					if (
						config.defaultView.indexOf('Resource') > -1 &&
						breakoutData.field === 'resource'
					) {
						if (!resourceMatch(breakoutData.value.name)) {
							event[breakoutData.field] =
								config.resources[0].name;
						}
					}
				}

				//Check if all day is available and all day is selected for event
				if (config.defaultView === 'agendaResourceHor' && allDay) {
					allDay = false;
					date = moment(date).add(
						moment.duration(config.gridTimes[0])
					);
				}

				//If the default calendar does not allow times, then we'll need to find the first one that does
				if (schedules[schedulePosition]) {
					primaryTimeConflict =
						schedules[schedulePosition].allowAllDay === false &&
						!allDay
							? true
							: false;
				}
			}

			//If the default calendar is not selected let's default to the first editable calendar
			//Also if the default does not allow times and a time was clicked, then we need to find the first editable calendar that does
			if (
				(!isUnscheduled &&
					(!schedules[schedulePosition] ||
						!schedules[schedulePosition].editable ||
						!schedules[schedulePosition].status.selected ||
						primaryTimeConflict)) ||
				(isUnscheduled &&
					!schedules[schedulePosition]?.allowUnscheduled)
			) {
				for (var i = 0; i < schedules.length; i++) {
					schedulePosition = undefined;

					//does the event have times, but the schedule doesn't allow?
					scheduleTimeConflict =
						schedules[i].allowAllDay === false && !allDay
							? true
							: false;

					if (
						!isUnscheduled &&
						horizonBreakoutField &&
						config.breakout &&
						breakoutData &&
						breakoutData.value.name !== noFilterLabel
					) {
						hasBreakoutField = schedules[i].breakout ? true : false;
					} else if (
						!isUnscheduled &&
						(!config.breakout || !horizonBreakoutField) &&
						breakoutData &&
						breakoutData.value.name !== noFilterLabel
					) {
						hasBreakoutField = fieldExistsForEvent(
							schedules[i],
							breakoutData.field
						);
					} else {
						hasBreakoutField = true;
					}

					if (isUnscheduled) {
						unscheduledMatch = schedules[i].allowUnscheduled
							? true
							: false;
					} else {
						unscheduledMatch = true;
					}

					//Let's check where the first visible editable calendar is
					if (
						unscheduledMatch &&
						hasBreakoutField &&
						schedules[i].status.selected &&
						schedules[i].editable &&
						schedules[i].fieldMap.eventID &&
						!scheduleTimeConflict
					) {
						schedulePosition = i;
						break;
					}
					//Grab position of first editable instance just in case none are visible
					else if (
						((isUnscheduled && schedules[i].allowUnscheduled) ||
							(!isUnscheduled &&
								!schedules[i].allowUnscheduled)) &&
						hasBreakoutField &&
						firstEditablePosition === undefined &&
						schedules[i].editable &&
						!scheduleTimeConflict
					) {
						firstEditablePosition = i;
					}
				}

				//Check if we have an available position
				if (schedulePosition === undefined) {
					//Use first editable
					if (firstEditablePosition !== undefined) {
						schedulePosition = firstEditablePosition;
						if (isUnscheduled) {
							createAfterRender = $rootScope.$on(
								'unscheduledRendered',
								function () {
									createAndOpen();
								}
							);
						} else {
							createAfterRender = $rootScope.$on(
								'eventsRendered',
								function () {
									createAndOpen();
								}
							);
						}
						//run routine to show this schedule
						manageSchedules.selectSchedule(
							schedules[schedulePosition]
						);
						return;
					}
					//If no editable calendars are found
					else {
						if (isUnscheduled) {
							// No editable unscheduled sources available
							var message =
								'Unscheduled is not enabled on any calendars. Please enable unscheduled on at least one calendar first.';
							utilities.showMessage(
								message,
								0,
								7000,
								'error',
								() => {
									utilities.help(
										'294-unscheduled-items',
										'294-unscheduled-items'
									);
								},
								false
							);
							return;
						}
					}
				}
			}

			createAndOpen();

			function resourceMatch(resource) {
				for (var i = 0; i < config.resources.length; i++) {
					if (resource === config.resources[i].name) {
						return true;
					}
				}
			}

			function createAndOpen() {
				if (createAfterRender) {
					// Stop watching for a render
					createAfterRender();
				}
				if (
					schedules[schedulePosition] &&
					schedules[schedulePosition].fieldMap &&
					!schedules[schedulePosition].fieldMap.eventID
				) {
					//no field mapping
					manageSchedules.selectSchedule(schedules[schedulePosition]);
					var message =
						"This event can't be created because the field mapping has not been completed for " +
						schedules[schedulePosition].name +
						'.<span class="message-icon"><i class="fa fa-question-circle fa-lg dbk_icon_help" style="display: inline-block; vertical-align: middle;"></i></span>';
					utilities.showMessage(
						message,
						0,
						7000,
						'error',
						errorFunction,
						false
					);
					return;
				}
				//contact or project according to url parameters?
				if (
					config.lookups &&
					config.lookups.contactID &&
					config.lookups.contactName
				) {
					event.contactID = [config.lookups.contactID];
					event.contactName = [config.lookups.contactName];
				}
				if (
					config.lookups &&
					config.lookups.projectID &&
					config.lookups.projectName
				) {
					event.projectID = [config.lookups.projectID];
					event.projectName = [config.lookups.projectName];
				}

				//Check to see if the item can be created based on resource filterList
				availableFilterItems = config.filterItems('resource');
				if (
					availableFilterItems &&
					availableFilterItems.length &&
					!event['resource']
				) {
					event['resource'] = [availableFilterItems[0].name];
				}

				//Status filter check
				availableFilterItems = config.filterItems('status');
				if (
					availableFilterItems &&
					availableFilterItems.length &&
					!event['status']
				) {
					event['status'] = [availableFilterItems[0].name];
				}

				//Check to see if we are in a horizon view and check to make sure any breakout data is available for new events
				if (horizonBreakoutField) {
					availableBreakoutItems =
						config.breakout && horizonBreakoutField !== 'schedule'
							? seedcodeCalendar.get('customFields')
							: config.filterItems(horizonBreakoutField);

					if (horizonBreakoutField === 'schedule') {
						availableBreakoutItems =
							seedcodeCalendar.get('breakoutSchedules');

						for (
							var i = 0;
							i < availableBreakoutItems.length;
							i++
						) {
							if (
								schedules[schedulePosition].id ===
								availableBreakoutItems[i].id
							) {
								breakoutSchedule = availableBreakoutItems[i];
							}
						}

						// Right now we don't have anything to do for schedule
						if (
							schedules &&
							breakoutSchedule &&
							breakoutSchedule.status.collapsed
						) {
							utilities.showMessage(
								'Could not create a new event because the breakout row for "' +
									schedules[schedulePosition].name +
									'" is collapsed.',
								null,
								6000
							);
							return;
						}
					} else if (config.breakout) {
						availableBreakoutItems = seedcodeCalendar.get(
							'breakoutCustomFields'
						);

						if (breakoutData) {
							event[
								schedules[schedulePosition].breakout
									? schedules[schedulePosition].breakout.field
									: horizonBreakoutField
							] = breakoutData.value.name;
						} else {
							// We only allow the first breakout "none" when not dragging the event to the breakout
							if (
								!availableBreakoutItems[0] ||
								availableBreakoutItems[0].status.collapsed
							) {
								utilities.showMessage(
									'Could not create a new event because the breakout row for "' +
										config.noFilterLabel +
										'" is collapsed.',
									null,
									6000
								);
								return;
							}
						}
					} else if (!breakoutData) {
						availableBreakoutItems =
							config.filterItems(horizonBreakoutField);
						if (
							availableBreakoutItems &&
							availableBreakoutItems.length
						) {
							for (
								var i = 0;
								i < availableBreakoutItems.length;
								i++
							) {
								if (
									!availableBreakoutItems[i].status ||
									!availableBreakoutItems[i].status.collapsed
								) {
									event[horizonBreakoutField] = [
										availableBreakoutItems[i].name,
									];
									break;
								}
							}
							if (!event[horizonBreakoutField]) {
								utilities.showMessage(
									translations[
										'Could not create a new event because all breakout items are hidden'
									],
									null,
									6000
								);
								return;
							}
						}
					}
				}

				// We no longer want to auto enter text filters
				// var textFilters = seedcodeCalendar.get('textFilters');
				// if(textFilters){
				// 	eventTitle = textFilters;
				// }

				if (!schedules[schedulePosition]) {
					return;
				}

				event.title = eventTitle;
				event.titleEdit = eventTitle;
				event.start = date;

				event.unscheduled = isUnscheduled;

				if (!schedules[schedulePosition].disableAllDay) {
					event.allDay = allDay;
				}

				createEventData(event, schedules[schedulePosition]);

				renderNewEvent(event);

				function errorFunction() {
					utilities.help('Field Mapping', '136-field-mapping');
				}
			}
		}

		function createEventData(event, schedule) {
			if (!event || !schedule) {
				return;
			}

			var config = seedcodeCalendar.get('config');
			var now = moment();

			if (!event.start) {
				event.start = now.clone();
			}

			event.eventSource = schedule.id;
			event.schedule = schedule;

			event.titleEdit = event.titleEdit || event.title;

			event.resource = event.resource || [];
			event.status = event.status || [];

			event.contactName = event.contactName || [];
			event.contactID = event.contactID || [];

			event.projectName = event.projectName || [];
			event.projectID = event.projectID || [];
			event.tags = event.tags || [];

			event.allDay = !event.allDay ? false : true;

			//times are allowed, but all Day is disabled. in this case the checkbox is hidden and allDay is off by default.
			//This is primarily for Salesforce Events when allDay is disabled for the org.
			if (
				schedule.unusedMap &&
				schedule.unusedMap.allDay &&
				schedule.allowAllDay
			) {
				// We only need to set the time if it doesn't exists in the start object (we can't assume midnight means the time wasn't set)
				// If the hasTime function doesn't exist, or it has a time, we can assume the time was explicitly set and we don't need to set it
				if (event.start.hasTime && !event.start.hasTime()) {
					event.start.hour(now.hour());
				}
				if (
					event.end?.isValid() &&
					event.end?.diff(event.start, 'minutes') <= 0
				) {
					event.end = moment(event.start).add(
						moment.duration(config.defaultTimedEventDuration)
					);
				}
				event.allDay = false;
			}

			if (!event.end) {
				if (!event.allDay) {
					event.end = moment(event.start).add(
						moment.duration(config.defaultTimedEventDuration)
					);
				} else {
					event.end = moment(event.start);
				}
			}
		}

		function deleteEvent(editEvent, callback, options) {
			var errorMessage;
			//cancelled from repeat modal dialog
			if (!editEvent) {
				errorMessage = 'Missing required data. Could not delete event.';
				utilities.showMessage(
					errorMessage,
					0,
					7000,
					'error',
					null,
					false
				);
				if (callback) {
					callback({error: {message: errorMessage}});
				}
				return;
			}

			if (options && options.confirmed === false) {
				return;
			}

			var event = editEvent.event;
			var deleteInstances = false;

			if (options && options.runCallback) {
				runCallback();
				return;
			}

			if (
				saveRequiresConfirmation(editEvent) &&
				(!options || !options.confirmed)
			) {
				confirmDeleteModal(editEvent, callback);
				return;
			}

			if (editEvent.eventID) {
				//Get an event action if any exist
				var actionCallbacks = {
					confirm: function (action) {
						action.preventAction = true;
						deleteEvent(editEvent, callback, options);
					},
					cancel: function (action) {
						//Some functionality can go here that represents a cancel callback
					},
				};
				var actionResult = runEventActions(
					'eventDelete',
					editEvent,
					true,
					actionCallbacks,
					null
				);
				//Return if the action result doesn't return true as we are preventing the default action
				if (!actionResult) {
					return;
				}

				if (options && options.all) {
					//delete whole series, delete source event
					//create an ad-hoc object to represent the original
					deleteInstances = 'all';
					editEvent.schedule.source.deleteSourceEvent(
						editEvent.event,
						runCallback
					);
				} else if (options && options.future) {
					//delete this and future events, update our rule
					deleteInstances = 'future';
					var until = editEvent.allDay
						? editEvent.start.clone().subtract('days', 1)
						: editEvent.start;
					editEvent.schedule.source.updateRepeatingEventUntil(
						editEvent.event,
						until,
						runCallback
					);
				} else if (options && options.confirmed) {
					deleteInstances = 'instance';
					editEvent.schedule.source.deleteRepeatingInstance(
						editEvent.event,
						runCallback
					);
				} else {
					editEvent.schedule.source.deleteEvent(
						editEvent.event,
						runCallback
					);
				}
			} else {
				confirmDelete(editEvent.event, null, callback);
			}

			function runCallback(data) {
				if (data && data.firstInstance) {
					deleteInstances = 'all';
					editEvent.schedule.source.deleteSourceEvent(
						editEvent.event,
						runCallback
					);
					return;
				}
				editEvent.sourceEvent = data;
				if (deleteInstances && deleteInstances !== 'instance') {
					var saveArray = [];
					var element = seedcodeCalendar.get('element');
					var events = element.fullCalendar('clientEvents');
					for (var i = 0; i < events.length; i++) {
						if (
							events[i].recurringEventID ===
								editEvent.recurringEventID &&
							(deleteInstances === 'all' ||
								events[i].start.format('YYYYMMDD') >=
									editEvent.start.format('YYYYMMDD'))
						) {
							saveArray.push(events[i]);
							element.fullCalendar('removeEvents', events[i]);
							events[i].removed = true;
						}
					}
					if (callback) {
						confirmDelete(event, 0, callback);
					}
				} else if (deleteInstances === 'instance') {
					confirmDelete(event, 0, callback);
				} else if (callback) {
					callback();
				}

				// Init eventShares array so we can concat with shares with old id's and new id's
				var options = {};
				options.eventShares = [];
				// Update with new event key (sourceType - source - event)
				updateShareEvent();

				// Update with old event key (sourceType - event)
				updateShareEvent(true);

				function updateShareEvent(depricatedEventID) {
					var hasShare;
					var shareEventID = (shareEventID =
						utilities.generateEventID(
							editEvent.event.schedule.sourceTypeID,
							editEvent.event.schedule.id,
							editEvent.event.eventID,
							depricatedEventID
						));

					//Check if event is shared and update accordingly
					daybackIO.getEventData(
						'shares',
						null,
						null,
						[shareEventID],
						function (shares) {
							//If the event is currently shared than update that shared event so it is properly synced
							if (shares && shares.length) {
								for (var i = 0; i < shares.length; i++) {
									//Check for valid share
									if (shares[i] && shares[i].id) {
										hasShare = true;
										shares[i].depricatedEventID =
											depricatedEventID;
										options.eventShares.push(shares[i]);
										daybackIO.setShareData(
											shares[i].id + '/events',
											shareEventID,
											null,
											false,
											null,
											null
										);
									}
								}
								if (hasShare) {
									options.isShared = true;
									editEvent.schedule.source.deleteSharedEvent(
										editEvent.event,
										null,
										null,
										depricatedEventID
									);
								}
							}
							//add repeating delete options
							options.deleteInstances = deleteInstances;
							//Call undo function
							undoEventChange(
								'deleted',
								'Deleted',
								event,
								editEvent,
								null,
								null,
								callback,
								options
							);
						},
						//Mutate function
						function (result) {
							var share = {};
							for (var property in result) {
								share.id = result[property].id;
							}
							return share;
						}
					);
				}
			}
		}

		function confirmDelete(event, error, callback) {
			var element = seedcodeCalendar.get('element');
			error = Number(error);
			if (error) {
				//Refetch events if we didn't cancel the request so we can update our events to reflect our events after error
				if (error > 1) {
					element.fullCalendar('refetchEvents');
				}
			} else {
				//Remove event from view
				fullCalendarBridge.removeEvents(element, event);
				event.removed = true;
				if (callback) {
					callback();
				}
			}
		}

		//We are no longer using this function. Maybe we can repurpose it for something in the future.
		function deleteEventNow(event, callback) {
			utilities.getFile('sc_update_event.txt', processDelete);
			function processDelete(error) {
				error = parseInt(error, 10);
				if (error) {
					//Refetch events if we didn't cancel the request so we can update our events to reflect our events after error
					if (error > 1) {
						editEvent.show = false;
						calendar.render.fullCalendar('refetchEvents');
					}
					return;
				}
				//Remove event from view
				fullCalendarBridge.removeEvents(
					seedcodeCalendar.get('element'),
					event
				);
				$rootScope.$broadcast('closePopovers');
				if (callback) {
					callback();
				}
			}
		}

		function cloneMappedField(mapObject, sourceTypeID, sharedSourceTypeID) {
			//Clone unused map to unused object
			var clonedMap = mapObject
				? JSON.parse(JSON.stringify(mapObject))
				: {};
			return clonedMap;
		}

		function isEventEditable(event) {
			var config = seedcodeCalendar.get('config');
			return config.readOnly ||
				(event.schedule && !event.schedule.editable)
				? false
				: true;
		}

		function createEditEvent(event, fieldMap, preventTimeshift) {
			var config = seedcodeCalendar.get('config');
			// var editable = !config.readOnly || !event.source || event.source.editable ? true : false;
			var editable = isEventEditable(event);
			var edit = {};
			var firstOpen;
			var isClone;
			var now = moment();
			var dateStart = null;
			var dateEnd = null;

			fieldMap = fieldMap || event.schedule.fieldMap;

			if (event.eventStatus && event.eventStatus.firstOpen) {
				isClone = event.eventStatus.isClone;
				firstOpen = true;
				event.eventStatus.firstOpen = false;
			}
			if (event.allDay && event.start) {
				dateStart = moment(event.start).hour(now.hour());
			} else if (event.start) {
				dateStart = moment(event.start);
			}
			if (event.allDay && event.end && !preventTimeshift) {
				dateEnd =
					firstOpen && moment().hour() < 22
						? moment(event.end)
								.hour(now.hour())
								.subtract(1, 'days')
								.add(1, 'hour')
						: moment(event.end)
								.hour(now.hour())
								.subtract(1, 'days');
			} else if (event.end) {
				dateEnd = moment(event.end);
			}

			//Set all mapped fields
			for (var property in fieldMap) {
				if (property === 'start') {
					edit[property] = dateStart;
				} else if (property === 'end') {
					edit[property] = dateEnd;
				} else if (property === 'titleEdit') {
					edit[property] =
						firstOpen &&
						!isClone &&
						(event.titleEdit === config.newEventTitleText ||
							!event.titleEdit)
							? null
							: event.titleEdit;
				} else if (Array.isArray(event[property])) {
					edit[property] = [...event[property]];
				} else if (
					typeof event[property] === 'object' &&
					event[property] !== null &&
					event[property] !== undefined
				) {
					edit[property] = {...event[property]};
				} else {
					edit[property] = event[property];
				}
			}

			//Set non mapped items
			edit.schedule = event.schedule;
			edit.event = event;
			edit.now = moment();
			edit.editable = editable;
			edit.contactObject = event.contactObject ? event.contactObject : '';
			edit.contactSearch = event.contactSearch ? event.contactSearch : '';
			edit.contactDisplay = event.contactDisplay
				? event.contactDisplay
				: '';
			edit.contactObjectDisplay = event.contactObjectDisplay
				? event.contactObjectDisplay
				: '';
			edit.projectObject = event.projectObject ? event.projectObject : '';
			edit.projectSearch = event.projectSearch ? event.projectSearch : '';
			edit.projectDisplay = event.projectDisplay
				? event.projectDisplay
				: '';
			edit.projectObjectDisplay = event.projectObjectDisplay
				? event.projectObjectDisplay
				: '';

			edit.labelMapOverride = event.labelMapOverride
				? JSON.parse(JSON.stringify(event.labelMapOverride))
				: '';

			edit.isDefaultResource = event.isDefaultResource;

			// Use hard-coded assigned resource instead of fieldmap
			if (event.schedule.useAssignedResources) {
				edit.resource = [...event.resource];
			}

			return edit;
		}

		function updateEditEvent(event, editEvent) {
			//Referenced in event action documentation Do not modify functionality
			//Updates the edit event object. This is good for updating popover info after saving an event but leaving the popover open.
			var editable =
				!event.schedule || event.schedule.editable ? true : false;
			var now = moment();
			var dateStart;
			var dateEnd;

			if (event.start) {
				dateStart = event.allDay
					? moment(event.start).hour(now.hour())
					: moment(event.start);

				if (event.allDay) {
					dateEnd = event.end
						? moment(event.end).hour(now.hour()).subtract(1, 'day')
						: moment(dateStart).add(1, 'hour');
				} else {
					dateEnd = event.end
						? moment(event.end)
						: dateStart.add(1, 'hour');
				}
			}

			for (var property in event) {
				if (property === 'start') {
					editEvent.start = dateStart;
				} else if (property === 'end') {
					editEvent.end = dateEnd;
				} else {
					editEvent[property] = event[property];
				}
			}
		}

		function quickSave(editEvent) {
			//We could run a routine here when doing a save from the quick entry popover
		}

		function getDisplayEvent(eventID, schedule) {
			var events = seedcodeCalendar
				.get('element')
				.fullCalendar('clientEvents');
			for (var i = 0; i < events.length; i++) {
				if (
					events[i].eventID === eventID &&
					(!schedule || events[i].schedule === schedule)
				) {
					return events[i];
				}
			}
		}

		function getValidShareActions(type, source, event) {
			var actions = source[type] ? getValidActions(source[type]) : null;
			var sourceScheduleID = utilities.stringToID(event.shareScheduleID);
			var scheduleActions;

			if (source[sourceScheduleID]) {
				scheduleActions = getValidActions(
					source[sourceScheduleID][type]
				);

				if (!actions && scheduleActions) {
					actions = {};
				}

				for (var property in scheduleActions) {
					actions[property] = scheduleActions[property];
				}
			}
			return actions;
		}

		function getValidShareDataMap(type, source, event) {
			var dataMap = source[type] ? source[type] : null;
			var sourceScheduleID = utilities.stringToID(event.shareScheduleID);
			var scheduleDataMap;

			if (source[sourceScheduleID]) {
				scheduleDataMap = source[sourceScheduleID][type];

				if (!dataMap && scheduleDataMap) {
					dataMap = {};
				}

				for (var property in scheduleDataMap) {
					dataMap[property] = scheduleDataMap[property];
				}
			}
			return dataMap;
		}

		function getValidActions(actions, event) {
			var isFileMakerWebViewer = utilities.getDBKPlatform() === 'dbkfm';
			var config = seedcodeCalendar.get('config');
			var result = {};

			if (!actions) {
				return;
			}

			for (var property in actions) {
				if (!actions[property].eventType && isFileMakerWebViewer) {
					if (isEventEditable(event)) {
						result[actions[property].id] = actions[property];
					}
				} else if (!actions[property].eventType) {
					continue;
				} else if (
					config.isShare &&
					actions[property].eventType.shared
				) {
					result[actions[property].id] = actions[property];
				} else if (
					!config.isShare &&
					!isEventEditable(event) &&
					actions[property].eventType.readonly
				) {
					result[actions[property].id] = actions[property];
				} else if (
					isEventEditable(event) &&
					actions[property].eventType.editable
				) {
					result[actions[property].id] = actions[property];
				}
			}
			return Object.keys(result).length ? result : null;
		}

		function runEventActions(
			type,
			editEvent,
			preventSave,
			actionCallbacks,
			callback,
			revertObject,
			changesObject,
			isUndo,
			element,
			jsEvent,
			params
		) {
			const config = seedcodeCalendar.get('config');
			const event = editEvent.event ? editEvent.event : editEvent;
			const shareSources = seedcodeCalendar.get('shareSources');
			let preventDefaultActionOverride;
			let preventActions;
			let shareSource;
			let eventActions;
			let hasValidActions = false;
			let hasPreventDefault = false;
			let hasConfirm = false;
			let hasCancel = false;
			const validActions = {};
			const actionPromises = [];

			// Check whether this is a share and if it is get actions from the shared source
			if (config.isShare) {
				for (var property in shareSources) {
					if (
						(event.shareSourceID === shareSources[property].id &&
							!shareSources[property].localParent) ||
						event.shareScheduleID === shareSources[property].id
					) {
						shareSource = shareSources[property];
						break;
					}
				}

				if (shareSource) {
					eventActions = getValidShareActions(
						'eventActions',
						shareSource,
						event
					);
				}
			} else {
				eventActions = getValidActions(
					editEvent.schedule.eventActions,
					event
				);
			}

			// Get an event action if any exist
			if (eventActions) {
				for (var property in eventActions) {
					if (
						eventActions[property].type === type &&
						eventActions[property].url
					) {
						validActions[property] = eventActions[property];
						hasValidActions = true;
						if (validActions[property].preventAction) {
							preventActions = true;
							eventActions[property].preventAction = null;
						}
					}
				}
			}

			if (!hasValidActions || preventActions) {
				// no valid action so return true so we don't prevent default
				return true;
			}

			if (!preventSave) {
				// Check if event needs to be saved and save before moving on
				if (type !== 'beforeEventSave') {
					const beforeSaveCallbacks = {
						confirm: (action) => {
							runSaveEvent(url, editEvent, options, () => {
								runEventActions(
									type,
									editEvent,
									true,
									actionCallbacks,
									callback,
									revertObject,
									changesObject,
									isUndo,
									element,
									jsEvent,
									params
								);
							});
						},
						cancel: (action) => {},
					};
					// Call before event save
					const actionResult = runEventActions(
						'beforeEventSave',
						editEvent,
						true,
						beforeSaveCallbacks,
						null
					);

					//Return if the action result doesn't return true as we are preventing the default action
					if (actionResult === true) {
						return false;
					}

					runSaveEvent(url, editEvent, options, () => {
						runEventActions(
							type,
							editEvent,
							true,
							actionCallbacks,
							callback,
							revertObject,
							changesObject,
							isUndo,
							element,
							jsEvent,
							params
						);
					});
				} else {
					runSaveEvent(url, editEvent, options, () => {
						runEventActions(
							type,
							editEvent,
							true,
							actionCallbacks,
							callback,
							revertObject,
							changesObject,
							isUndo,
							element,
							jsEvent,
							params
						);
					});
				}
			} else {
				// no save necessary so just run the actions
				for (var property in validActions) {
					if (
						eventActions[property].preventDefault &&
						type !== 'beforeEventRendered'
					) {
						// prevent default
						hasPreventDefault = true;
						actionPromises.push(
							new Promise((resolve, reject) => {
								const promiseCallbacks = {
									confirm: () => {
										resolve();
									},
									cancel: () => {
										reject();
									},
								};

								eventActions[property].callbacks =
									promiseCallbacks;

								eventAction(
									editEvent,
									eventActions[property],
									callback,
									true,
									revertObject,
									changesObject,
									element,
									jsEvent,
									isUndo,
									params
								);
							})
						);
					} else {
						// not prevent default

						// Make a prevent default exception for beforeEventRendered as we don't allow async calls there
						if (
							type === 'beforeEventRendered' &&
							eventActions[property].preventDefault
						) {
							hasPreventDefault = true;
						}

						const actionCallbacks = {
							confirm: () => {
								hasConfirm = true;
							},
							cancel: () => {
								hasCancel = true;
							},
						};

						eventActions[property].callbacks = actionCallbacks;

						const actionResult = eventAction(
							editEvent,
							eventActions[property],
							callback,
							true,
							revertObject,
							changesObject,
							element,
							jsEvent,
							isUndo,
							params
						);

						if (actionResult === true) {
							//If the result of a event action function is true we override prevent default
							preventDefaultActionOverride = true;
						}
					}
				}
			}

			let primaryAction;
			for (const property in validActions) {
				primaryAction = validActions[property];
				break;
			}

			if (type === 'beforeEventRendered') {
				if (hasCancel) {
					actionCallbacks.cancel();
				} else if (hasConfirm) {
					actionCallbacks.confirm();
				}
			} else if (hasPreventDefault) {
				Promise.all(actionPromises)
					.then((result) => {
						actionCallbacks.confirm(primaryAction);
					})
					.catch((err) => {
						actionCallbacks.cancel(primaryAction);
					});
			}

			return !hasPreventDefault && !preventDefaultActionOverride;
		}

		//Run event action. These are passed in from our datasource (Filemaker) and run scripts there
		function eventAction(
			editEvent,
			action,
			callback,
			preventSave,
			revertObject,
			changesObject,
			targetElement,
			jsEvent,
			isUndo,
			params
		) {
			if (!callback && (!action || !action.url)) {
				return;
			}

			if (!action) {
				action = {};
			}

			const newWindow = action.newWindow;
			const isBeforeEventSave = action.type === 'beforeEventSave';

			const options = {};

			let url = action.url;
			let result;

			options.isUndo = !!isUndo;

			if (url && !isFunction(url)) {
				//We didn't detect any function calls so treat as url
				if (newWindow) {
					url = "open('" + url + "','_blank');";
				} else {
					url = "location.href = '" + url + "';";
				}
			}

			url = manageCalendarActions.substituteOpenCommand(url);

			if (!preventSave) {
				if (!isBeforeEventSave) {
					// Call before event save
					runBeforeEventSave();
				} else {
					runSaveEvent(url, editEvent, options, () => {
						manageCalendarActions.callEventAction(
							action,
							url,
							editEvent,
							jsEvent,
							targetElement,
							params,
							changesObject,
							revertObject,
							isUndo,
							callback
						);
					});
				}
			}
			// If the event doesn't need to be saved first just run the action
			else {
				manageCalendarActions.callEventAction(
					action,
					url,
					editEvent,
					jsEvent,
					targetElement,
					params,
					changesObject,
					revertObject,
					isUndo,
					callback
				);
			}

			function runBeforeEventSave() {
				var actionCallbacks = {
					confirm: function (action) {
						runSaveEvent(url, editEvent, options, () => {
							manageCalendarActions.callEventAction(
								action,
								url,
								editEvent,
								jsEvent,
								targetElement,
								params,
								changesObject,
								revertObject,
								isUndo,
								callback
							);
						});
					},

					cancel: function (action) {},
				};
				const actionResult = runEventActions(
					'beforeEventSave',
					editEvent,
					true,
					actionCallbacks,
					null
				);

				// Return if the action result doesn't return true as we are preventing the default action
				if (!actionResult) {
					return false;
				}

				runSaveEvent(url, editEvent, options, () => {
					manageCalendarActions.callEventAction(
						action,
						url,
						editEvent,
						jsEvent,
						targetElement,
						params,
						changesObject,
						revertObject,
						isUndo,
						callback
					);
				});
			}

			return result;
		}

		function runSaveEvent(url, editEvent, options, saveCallback) {
			// Test if our event has been modified and needs to be saved. Returns the editEvent object
			const modifiedEvent = !editEvent.event
				? false
				: eventChanged(editEvent, editEvent.event);
			// If our event has been modified we need to save it
			// If the event needs to be saved
			if (!modifiedEvent) {
				saveCallback();
				return;
			}
			// Set options
			options.showConfirmation = saveRequiresConfirmation(
				editEvent.event
			);
			options.isCustomAction = true;

			// Normalize end date in edit object for all day events
			options.endShifted = false;
			getEventChanges(
				editEvent.event,
				editEvent,
				null,
				(event, changes, revertObject, revertFunc, options) => {
					// Run our routine for after checking if an event was saved or not (should be callback for updateEvent)
					if (!options || !options.isUndo) {
						saveCallback();
					}
				},
				options
			);
		}

		function isFunction(text) {
			if (!text) {
				return;
			}

			return (
				(text.indexOf('(') !== -1 && text.indexOf(')') !== -1) ||
				text.indexOf(';') !== -1
			);
		}

		function fieldExistsForEvent(schedule, field) {
			var customFields = schedule.customFields;
			var fieldMap = schedule.fieldMap;
			var unusedMap = schedule.unusedMap;
			var labelMap = schedule.labelMap;
			var defaultLabelMap = seedcodeCalendar.get('defaultLabelMap');

			var hasBuiltInField;
			var hasCustomField;

			if (field === 'schedule') {
				return true;
			}

			if (fieldMap[field] && !unusedMap[field]) {
				hasBuiltInField = true;
			}

			if (customFields) {
				for (var property in customFields) {
					if (customFields[property].id === field) {
						hasCustomField = true;
					}
				}
			}

			return hasBuiltInField || hasCustomField;
		}

		//helper function cleares previously-saved revert function
		function clearRevertFunction() {
			revertHistory.undo = undefined;
			revertHistory.undoType = null;
			revertHistory.events = [];
		}

		//helper function saves the revert function
		function saveRevertFunction(revertFunction, undoType, events = []) {
			revertHistory.undo = revertFunction;
			revertHistory.undoType = undoType;
			revertHistory.events = events;
		}

		//helper function clears a previously saved revert function,
		function runRevertFunction(keyboardShortcut = false) {
			// Get config object
			let config = seedcodeCalendar.get('config');

			if (typeof revertHistory.undo === 'function') {
				// Prevent Keyboard shortcuts while function is running
				config.preventUndoKeyboardShortcut = true;

				// Get last saved revert function
				let revertFunction = revertHistory.undo;

				// Get list of revertable eventIDs
				let eventIDs = revertHistory.events;

				// Get Undo type
				let undoType = revertHistory.undoType;

				// Clear it from the save stack
				clearRevertFunction();

				// Get the result in a try catch block and reset keyboard shortcut when finished
				let result;

				try {
					result = revertFunction();

					config.preventUndoKeyboardShortcut = false;

					// If undo triggered from keyboard shortcut, show revert successful message
					if (
						keyboardShortcut &&
						!config.suppressMultiUpdateUndoMessage
					) {
						let successMessage =
							'<span translate>Changes Reverted</span>';
						let eventsShowing = areEventsShowing(eventIDs);

						if (!eventsShowing && undoType !== 'deleted') {
							successMessage =
								'<span translate>Changes Reverted</span>. <span translate>Event may be off screen</span>.';
						}

						let revertMessage =
							'<span class="message-icon-separator success">' +
							'<i class="fa fa-lg fa-check"></i>' +
							'</span>' +
							successMessage;

						utilities.showMessage(
							revertMessage,
							0,
							config.undoTimeout,
							null,
							null
						);
					} else if (
						keyboardShortcut &&
						config.suppressMultiUpdateUndoMessage
					) {
						delete config.suppressMultiUpdateUndoMessage;
					}
				} catch (error) {
					config.preventUndoKeyboardShortcut = false;
					delete config.suppressMultiUpdateUndoMessage;
				}

				return result;
			} else if (keyboardShortcut) {
				let revertMessage =
					'<span class="message-icon-separator notSuccess">' +
					'<i class="fa fa-lg fa-info-circle"></i>' +
					'</span>' +
					'<span translate>No Change History Available</span>';

				utilities.showMessage(
					revertMessage,
					0,
					config.undoTimeout,
					null,
					null
				);
			}

			// Function returns true if all events are shown. Current edge case will incorrectly
			// return true if in multi-page resource view, but user is on a different page of
			// resources.
		}

		// helperfunction returns true if every event in the array of eventsIDs is currently showing
		function areEventsShowing(eventIDs = []) {
			// Get config object
			let config = seedcodeCalendar.get('config');

			// Get all current client events
			let clientEvents = seedcodeCalendar
				.get('element')
				.fullCalendar('clientEvents');

			let expectedCount = eventIDs.length;
			let shownCount = 0;

			eventIDs.forEach((eventID) => {
				let eventObj = clientEvents.find((e) => e.eventID == eventID);
				if (eventObj && config.eventShown(eventObj)) {
					shownCount++;
				}
			});

			return expectedCount == shownCount ? true : false;
		}
	}
})();
